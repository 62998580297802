<div role="main">


<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/client.jpg" alt="First slide">
    </div>
  </div>
</div>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Clients
    </li>
  </ol>
</nav>
<section class="section-padding">
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="section-heading text-dark">Valuable Clients of <span>SpinoInc</span></h1>
        </div>
      </div>
        <br> 
        <div class="row">
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="alm" href="http://www.alm.com/" target="_blank">
          <div class="text-center imgap imgpad "><img src="assets/images/clients/alm.png" class="img-fluid  animationLeft" alt="icon" width="204" height="90"></div></a>
        </div>
        
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="wipro" href="https://www.wipro.com/" target="_blank">
          <div class="text-center imgap imgpad "> <img src="assets/images/clients/Wipro-Logo.png" class="img-fluid  animationLeft" alt="icon" width="204" height="90">
          </div></a>
        </div>
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="cap" href="http://www.capgemini.com/" target="_blank"> 
          <div class="text-center imgap imgpad "><img src="assets/images/clients/capgemini.png" class="img-fluid animationRight" alt="icon" width="204" height="90"></div></a>
        </div>
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="del" href="http://www2.deloitte.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/deloitt.png" class="img-fluid animationRight" alt="icon" width="204" height="90"></div></a>
        </div>
      <!-- Client -->
      </div>
      <br> <br> 
      <div class="row">
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="mod" href="https://www.modis.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/Modis_Logo.jpg"  class="img-fluid  animationLeft" alt="icon" width="204" height="90"></div></a>
        </div>
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"> <a aria-label="ets" href="http://www.ets.org/" target="_blank">
          <div class="text-center imgap imgpad"><img  src="assets/images/clients/ets.png"  class="img-fluid  animationLeft" alt="icon" width="204" height="90">
         </div> </a>
        </div>
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="knip" href="https://www.knipper.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/knipper.png" alt="icon" class="img-fluid animationRight" width="204" height="90"></div></a>
        </div>
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="experis" href="https://www.experis.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/experis_logo.png" alt="icon" class="img-fluid animationRight" width="204" height="90">
          </div></a>
        </div>
      </div>
      <br> <br> 
      <div class="row">
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="apple" href="http://www.apple.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/apple.png"  class="img-fluid  animationLeft" alt="icon" width="204" height="70"></div></a>
        </div>
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="deep" href="http://www.deepaauto.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/deepaauto.png"  class="img-fluid  animationLeft" alt="icon" width="204" height="90"></div></a>
        </div>
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="hotel" href="http://www.hotelexecutivesuites.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/hotelexe.png" alt="icon" class="img-fluid animationRight" width="204" height="90"></div></a>
        </div>
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="ubs" href="http://www.ubs.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/ubsbank.png" alt="icon" class="img-fluid animationRight" width="204" height="90"></div></a>
        </div>
      </div>
      <br> <br> 
      <div class="row">
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"> <a aria-label="acc" href="https://www.accenture.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/accenture_logo.PNG"  class="img-fluid  animationLeft" alt="icon" width="204" height="90">
          </div></a>
        </div>
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="yoh" href="https://www.yoh.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/Yoh-logo.png"  class="img-fluid  animationLeft" alt="icon" width="204" height="90">
          </div></a>
        </div>
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="torr" href="http://torrentialinc.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/torrential_logo.png" class="img-fluid animationRight" alt="icon" width="204" height="90">
          </div></a>
        </div>
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="rob" href="https://www.roberthalf.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/robert-half-international.png" class="img-fluid animationRight" alt="icon">
          </div></a>
        </div>
      </div>
      <br> <br> 
      <div class="row">
        <!-- Client -->
        <div class="col-md-3 col-sm-4  hover geeks"><a aria-label="con" href="https://www.coned.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/logo.svg"  class="img-fluid  animationLeft" alt="icon" width="204" height="90">
          </div></a>
        </div>
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="ext" href="https://extremereach.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/er_stacked_logo.svg"  class="img-fluid  animationLeft" alt="icon" width="204" height="90">
          </div></a>
        </div>
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="mtb" href="https://www.mtb.com/personal/Pages/Index.aspx" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/mandt.png" class="img-fluid animationRight" alt="icon" width="204" height="90"></div></a>
        </div>
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="bar" href="http://www.barclays.co.uk/PersonalBanking/P1242557947640" target="_blank">
          <div class="text-center imgap imgpad">  <img src="assets/images/clients/barclays.png" class="img-fluid animationRight" alt="icon" width="180" height="90"></div></a>
        </div>
        <!-- Client -->
        <!-- Client -->
      </div>
      <br> <br> 
      <div class="row">
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="granite" href="http://www.granitenet.com/" target="_blank"> 
          <div class="text-center imgap imgpad"><img src="assets/images/clients/granite.png"  class="img-fluid  animationLeft" alt="icon" width="204" height="90"></div></a>
        </div>
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="dhl" href="https://www.dhl.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/dhl-logo.png"  class="img-fluid  animationLeft" alt="icon" width="204" height="80">
          </div></a>
        </div>
        <div class="col-md-3 col-sm-4 hover geeks"><a aria-label="cvscare" href="https://www.caremark.com/wps/portal" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/cvscare.png" class="img-fluid animationRight" alt="icon" width="204" height="80"></div></a>
        </div>
        <div class="col-md-3 col-sm-4 hover geeks"> <a aria-label="cognizant" href="http://www.cognizant.com/" target="_blank">
          <div class="text-center imgap imgpad"><img src="assets/images/clients/cognizant.png" alt="icon"  class="img-fluid animationRight" width="204" height="80"></div></a>
        </div>
        <!-- Client -->
        <!-- Client -->
      </div>
      <br> <br> 
      <div class="row">
        <!-- Client -->
        <div class="col-md-3 col-sm-4 hover geeks"> <a aria-label="teksys" href="https://www.teksystems.com/" target="_blank">
          <div class="text-center imgap imgpad"> <img src="assets/images/clients/teksystems_logo.png" alt="icon" width="204" height="90">
          </div></a>
        </div>

        <!-- Client -->
        <!-- Client -->
      </div>
      <br> <br> 
    </div>
  </div>
</section>
</div>