import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/shared/services/server.service';
import { Router } from '@angular/router';

import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-gallery-list',
  templateUrl: './gallery-list.component.html',
  styleUrls: ['./gallery-list.component.css']
})
export class GalleryListComponent implements OnInit {

  model: any ={};
  blogedit: any;
  GalleryList: any;
  Fail:boolean;
  Success:boolean;
  GalleryId: any;
  nodata = false;
  successMessage:any;
  errorMessage:any;
  private unsubscribe$ = new Subject<void>();
  subs:Subscription;

  constructor(private serverService: ServerService,private router:Router,) { }

  ngOnInit() {
    this.FetchGalleryList()
  }

  FetchGalleryList(){
    this.model.AccountId=2
    
   this.subs= this.serverService.FetchGallery(this.model)    
    .subscribe(
   (data:any )=>{ 
          console.log(data);
      
      this.GalleryList={};
      this.GalleryList=data.GalleryList;
      if( this.GalleryList=="" || this.GalleryList.length==0){
                this.nodata=true;
              }
              else{
                this.nodata=false;
              }
   } );

  }

  DeleteGallery(GalleryId, Status){
    
    // alert("Are you sure you want to Delete");
    this.model={};
     this.model.GalleryId=GalleryId;
     if(Status==='Yes'){
     this.serverService.RemoveGallery( this.model)
     .pipe(takeUntil(this.unsubscribe$))
     .subscribe( 
       res=>{
      
         console.log(res)
          if(res['Response'].status ==200){ 
           this.Success =true;
          this.successMessage= res['GalleryData'].ReturnVal;
          setTimeout(() => {
           this.Success = false;
           this.successMessage = '';
                   }, 5000);
                  //  this.FetchGalleryList();
          }
          else{
            this.Fail=true;
            this.errorMessage= 'Error while processing';
            setTimeout(() => {
             this.Fail = false;
             this.errorMessage = '';
                     }, 5000);
          }
         }
     );
     this.FetchGalleryList();
        }
   }
   ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
