import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHtmltags'
})
export class RemoveHtmltagsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
        
        return value.replace(/<[^>]+>/gm, '');
      }

}
