
  <header class="innner-page">
    <div class="container">
      <h1><i class="fa fa-angle-double-right" aria-hidden="true"></i>Our Team List</h1>
    </div>
  </header>
  <div class="col breadcrumb-wizard">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb no-gutters">
        <li class="breadcrumb-item">
          <a placement="bottom" ngbTooltip="Dashboard" routerLink="/dashboard" skipLocationChange>Home
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Team List
        </li>
      </ol>
    </nav>
  </div>
  <div class="container padding" role="main">
    <div class="row ">
      <div class="col-md-4">
      </div>
      <div class=" col-md-4 text-center">
        <h2> Team List</h2>
      </div>
      <div class="col-md-4 text-right addpad">
        <button routerLink="/crudteam" class="btn btn-success button-blue btn-border-radius mr-3" >
          <span >Add Team</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="alert alert-success" role="alert" *ngIf="Success">
          <button type="button" class="close" data-dismiss="alert">&times;</button>
          {{ successMessage }}
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="Fail">
          <button type="button" class="close" data-dismiss="alert">&times;</button>
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-12 mx-auto table-responsive" *ngIf="nodata==false">
        <!-- <div class="row">
          <div class="col-lg-8 mx-auto table-responsive "> -->
            <div>
            <table class="table table-hover" >
              <thead>
                <tr>
                  <th class="text-center">S.No</th>
                  <th class="text-left">Image</th>
                  <th class="text-left thpad" >Employee Name</th>
                  <th class="text-left thpad">Employee Title</th>
                  <!-- <th class="text-left">Role</th> -->
                  <th >Delete</th>
                </tr>
              </thead>
  
              <tbody >
                <tr *ngFor="let user of TeamList; let i = index">
                  <td class="text-center">{{i+1}}</td>
                  <td class="text-left" >
                     <img [src]="user.ImagePath" alt="No Image" height="30" width="70" style="margin-right:10px;">                  
                  </td>
                  <td class="text-left">
                  
                    <button type="button" class="btnn btn btn-link TitleText thpad" (click)="upadteTeam( user)"
                       placement="bottom"  aria-expanded="false" aria-label="team" ngbTooltip="Modify Team">{{user.EmpFullName}}
                    </button>
                  </td>
                  <td class="text-left Descriptiontext thpad">{{user.EmpTitle}}</td>
                  <!-- <td class="text-left Descriptiontext">{{user.RoleResponsiblity | slice:0:15}}</td> -->
                  
                  <td>
                    <div class="align-div-center">
                      <!-- MODEL REFERENCE -->
                      <button type="button" class="btn btn-outline-danger" data-toggle="modal" data-target="#myModal" aria-expanded="false" aria-label="delteam"
                         placement="bottom" ngbTooltip="Delete Team" (click)="DeleteTeam(user.EmployeeId, '')">
                        <span class="fa fa-times-circle-o"></span>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          <!-- </div>
        </div> -->
      </div>
      <div class="col-md-12 "  *ngIf="nodata==true" >
        <div class="Nodata"> No Team Data Found</div>
        </div>
    </div>
  </div>
  
  <!-- data-toggle="modal" data-target="#myModal" -->
  <div id="myModal" class="modal fade" aria-label="alertteam" role="alertdialog" name="alert">
    <div class="modal-dialog modal-confirm">
      <div class="modal-content">
        <div class="modal-header">
          <div class="icon-box">
            <i class="fa fa-times"></i>
          </div>				
          <h3 class="modal-title">Are you sure?</h3>	
                  <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <p>Do you really want to delete this record? This process cannot be undone.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="DeleteTeam(model.EmployeeId,'Yes')">Delete</button>
        </div>
      </div>
    </div>
  </div> 