import { Component, OnInit,Inject} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ServerService } from 'src/app/shared/services/server.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  model: any ={};
  GalleryList: any;
  galleryImages: any;
  Galimage: any;
  nodata=false;
  len: any; 
  elem;
  offscreen=false;
  fullscreen=true;
  constructor(private serverService: ServerService, private router:Router, //private location: Location,
    @Inject(DOCUMENT) private document: any, private spinner: NgxSpinnerService)
     { 
      
     }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);
    this.FetchGalleryList();
  }
 
  FetchGalleryList(){
    this.model.AccountId=2 
    this.serverService.FetchGallery(this.model)    
    .subscribe(
      (data:any )=>{ 
       this.GalleryList={};
       this.GalleryList=data.GalleryList;
       if( this.GalleryList=="" || this.GalleryList.length==0){
            this.nodata=true;
          }
          else{
            this.nodata=false;
            this.galleryImages = this.GalleryList[0].ImagePath;
            this.len =this.GalleryList.length;
          }
       
       //console.log(this.GalleryList);
 } );
}
ViewTeam(Gallery){
  this.serverService.setdata(Gallery);
  this.model= this.serverService.getdata();
  this.Galimage= this.model.ImagePath;
 
  
}
 openModal() {
  window.scrollTo(0,0);
  document.getElementById("myModal").style.display = "block";
 
}
closeModal() {
  document.getElementById("myModal").style.display = "none";
  if(this.offscreen==true){
    this.closeFullscreen();
  }
  
}
 slideIndex: any = 1;
// showSlides(slideIndex);

plusSlides(n) {
  this.showSlides(this.slideIndex += n);
}
currentSlide(n) {
  this.showSlides(this.slideIndex = n);
}

showSlides(n) {
  var i;

  var slides = document.getElementsByClassName("mySlides");
  // var dots = document.getElementsByClassName("demo");
  var captionText = document.getElementById("caption");
  if (n > slides.length) {this.slideIndex = 1}
  if (n < 1) {this.slideIndex = slides.length}
  for (i = 0; i < slides.length; i++) {
      slides[i]['style'].display = "none";
       
  }
  slides[this.slideIndex-1]['style'].display = "block";
}

openFullscreen() {
  this.offscreen=true;
  this.fullscreen=false;
  if (this.elem.requestFullscreen) {
    this.elem.requestFullscreen();
  } else if (this.elem.mozRequestFullScreen) {
    /* Firefox */
    this.elem.mozRequestFullScreen();
  } else if (this.elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    this.elem.webkitRequestFullscreen();
  } else if (this.elem.msRequestFullscreen) {
    /* IE/Edge */
    this.elem.msRequestFullscreen();
  }
}

/* Close fullscreen */
closeFullscreen() {
  this.offscreen=false;
  this.fullscreen=true;
  if (this.document.exitFullscreen) {
    this.document.exitFullscreen();
  } else if (this.document.mozCancelFullScreen) {
    /* Firefox */
    this.document.mozCancelFullScreen();
  } else if (this.document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    this.document.webkitExitFullscreen();
  } else if (this.document.msExitFullscreen) {
    /* IE/Edge */
    this.document.msExitFullscreen();
  }
}

}
