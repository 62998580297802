import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {VisitorManagementSystemComponent} from './visitor-management-system/visitor-management-system.component';
import {TokenManagementSystemComponent} from './token-management-system/token-management-system.component';
import {ItsMyturnComponent} from './its-myturn/its-myturn.component'
import { RouterModule, Routes } from '@angular/router';
import { ProductsComponent } from './products.component';

const routes: Routes = [
  {path: '', component: ProductsComponent},
  {path: 'visitor', component: VisitorManagementSystemComponent},
  {path: 'token', component: TokenManagementSystemComponent},
  {path: 'itsmyturn', component: ItsMyturnComponent},
]

@NgModule({
  declarations: [
    TokenManagementSystemComponent,
    VisitorManagementSystemComponent,
    ItsMyturnComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ]
})
export class ProductsModule { }
