import { Component, OnInit , OnDestroy} from '@angular/core';
import { ServerService } from 'src/app/shared/services/server.service';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit,OnDestroy  {

  model: any ={};
  blogedit: any;
  ImagePath: any;
  BlogList: any;
  Fail:boolean;
  Success:boolean;
  ArticleId: any;
  successMessage:any;
  nodata= false;
  errorMessage:any;
  private unsubscribe$ = new Subject<void>();
  subs:Subscription;
  constructor(private serverService: ServerService,private router:Router,) { }


  ngOnInit(): void {
    this.FetchBlogList();
  }

  FetchBlogList(){
    this.model.AccountId=2
   this.subs= this.serverService.FetchArticles(this.model)
   .pipe(takeUntil(this.unsubscribe$))    
    .subscribe(
   (data:any )=>{ 
       console.log(data);
      this.BlogList={};
      const filterValue = "BLOG";
      const result = data.ArticleList.filter(element => {
        return element.ArticleType === filterValue;
      });
            this.BlogList =result;
            if( this.BlogList=="" || this.BlogList.length==0){
                          this.nodata=true;
                        }
                        else{
                          this.nodata=false;
                        }
   } );

  }
  //to edit and update blog
  upadteBlog(user){
    this.serverService.setdata(user);
    this.router.navigateByUrl('/crudblog')
    
      }

      
  DeleteBlog(ArticleID, Status){
   this.model={};
    this.model.ArticleId=ArticleID;
   if(Status==='Yes'){
    // debugger;
    this.serverService.DeleteArticle( this.model.ArticleId).subscribe(
      res=>{
        console.log(res)
       
         if(res['Response'].status ==200){ 
          this.Success =true;
         this.successMessage= res['ArticleData'].ReturnVal;
         setTimeout(() => {
          this.Success = false;
          this.successMessage = '';
                  }, 3000);
                  
                  // this.FetchBlogList();
         }
         else{
           this.Fail=true;
           this.errorMessage= 'Error while processing';
           setTimeout(() => {
            this.Fail = false;
            this.errorMessage = '';
                    }, 3000);
         }
        });
      
          // this.FetchBlogList();
               
       
      }
      this.FetchBlogList();
  }

   ngOnDestroy(){
    this.model={};
    // this.subs.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  
  }
}
