import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/shared/services/server.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-archivejobs',
  templateUrl: './archivejobs.component.html',
  styleUrls: ['./archivejobs.component.css']
})
export class ArchivejobsComponent implements OnInit {

  model: any ={};
  archivejobList : any;
  constructor(private serverService: ServerService, private router:Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);
    this.FetchArchivejoblist();
  }

  FetchArchivejoblist(){
    this.model.AccountId=2
    this.serverService.FetchArchiveJobs(this.model).subscribe(
   (data:any )=>{ 
      this.archivejobList=data.ArchiveJobsList;
      //console.log(this.archivejobList);
   } );

  }
  ViewArchivejob(user){
    // this.serverService.setdata(user);
    // this.router.navigateByUrl("/archivedetails")
    this.serverService.setdata(user);
    const EncodeURL = btoa(user.JobId);
    // console.log(EncodeURL);
    this.router.navigate(["/archivedetails"], { queryParams: { q: EncodeURL } });
  }


}
