import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms'; //imports
import { ServerService } from 'src/app/shared/services/server.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-blogdetails',
  templateUrl: './blogdetails.component.html',
  styleUrls: ['./blogdetails.component.css']
})
export class BlogdetailsComponent implements OnInit {

model: any ={};
BlogList: any;
imgURL: any;
blogTitle: any;
nodata= false;
blogId: any;
blogDescription:any;
private unsubscribe$ = new Subject<void>();
subs:Subscription;
QueryParamId: any;
BlogData:any = {};
  constructor(private serverService: ServerService, private route: ActivatedRoute, private router: Router) { 
  }

  ngOnInit() {
    // this.viewBlog();
    // this.FetchBlogList();

    this.route.queryParamMap.subscribe((URLparams: any) => {
      const EncodeURL = URLparams.params.q;
      this.QueryParamId = atob(EncodeURL);
      // console.log(this.QueryParamId);
    }); 
    this.viewBlogBYURL(this.QueryParamId);
    this.FetchBlogList(this.QueryParamId);

  }

  viewBlogBYURL(Id){  
    this.redirect();
   
    this.model.AccountId=2
     this.subs=  this.serverService.FetchArticles(this.model)
     .pipe(takeUntil(this.unsubscribe$))    
      .subscribe(
        (data : any) =>{
         // console.log(Id);
          
          this.BlogData = data.ArticleList.filter(element => {
           // console.log(element.ArticleId);
            return element.ArticleId === parseInt(Id) ;
          });
          this.blogId=this.BlogData[0].ArticleId;
         // console.log(this.BlogData[0]);
         this.blogTitle = this.BlogData[0].ArticleTitle;
        this.blogDescription = this.BlogData[0].ArticleDescription;
        this.imgURL=this.BlogData[0].ImagePath;
        });
     
             
    }


  viewBlog(){ 
    this.model= this.serverService.getdata();
   // console.log(this.model);
    this.blogId=this.model.ArticleId;
      this.blogTitle = this.model.ArticleTitle;
    this.blogDescription = this.model.ArticleDescription;
    this.imgURL=this.model.ImagePath
      
    
    this.serverService.blogdatatoedit=undefined;     
  }

  FetchBlogList(Id){
    this.model.AccountId=2
    
   this.subs= this.serverService.FetchArticles(this.model)
   .pipe(takeUntil(this.unsubscribe$))    
    .subscribe(
   (data:any )=>{ 
          // console.log(data);
      // this.BlogList=data.ArticleList;
      const filterValue = "BLOG";
      const result = data.ArticleList.filter(element => {
        return element.ArticleType === filterValue;
      });
            this.BlogList=result;
            let index = this.BlogList.findIndex(d => d.ArticleId ===  parseInt(Id)); //find index in your array
            this.BlogList.splice(index, 1);//remove element from array
            if(this.BlogList.length==0){
                          this.nodata= true;
                        } else {
                          this.nodata = false;
                        }
   } );

  }
  
  redirect() {
    window.scroll({ 
      top: 50, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
  // OpenBlogToLeftSide(blog){
  //   this.redirect();
  //   this.serverService.setdata(blog);
  //  this.viewBlog();
  //  this.FetchBlogList(this.blogId);
  // }
  OpenBlogToLeftSide(blog){
    const EncodeURL = btoa(blog.ArticleId);
  this.router.navigate(['/']).then(() => { this.router.navigate(['/blogdetails' ], { queryParams: { q: EncodeURL } }); })
  }
}

