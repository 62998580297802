<div role="main">


  <div>
    <img class="img-fluid" src="assets/images/ItsMyTurn.png" alt="Spino Image">
  </div>
  <div >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">ItsMyTurn
        </li>
      </ol>
    </nav>
  </div>
<div class="container-fluid p-4">
  <br>
  <h1 aria-label="heading"></h1>
  <div class="font-size"><b>It'sMyturn : </b></div>
  <br>
  <p>
    This application is design to get updated queue list of people waiting for their turn to service provider like doctor , hotel, restaurant, parlor etc. 
     </p>
     <p>
      It allows users to update about places being crowded to products not available, long waiting time at restaurants, hotels etc. ... a major source of dissatisfaction for people is excessive queuing and waiting at public places.      
     </p>
</div>
</div>