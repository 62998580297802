import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/shared/services/server.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-ourteam',
  templateUrl: './ourteam.component.html',
  styleUrls: ['./ourteam.component.css']
})
export class OurteamComponent implements OnInit {
  model: any ={};
  TeamList: any;
  teaminfo : any;
  imgURL: any;
  TeamName: any;
  TeamTitle:any;
  role:any;
  nodata: any = false;

  constructor(private serverService: ServerService, private router:Router, private location: Location, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);
    this.FetchTeamList();
  }
  
  FetchTeamList(){
    this.model.AccountId=2
    this.serverService.FetchOurTeam(this.model).subscribe(
   (data:any )=>{ 
      this.TeamList=data.OurTeamList;
      if( this.TeamList=="" || this.TeamList.length==0){
           this.nodata=true;
         }
         else{
           this.nodata=false;
         }
       //console.log(this.TeamList);
   } );

  }
  ViewTeam(user){
    this.serverService.setdata(user);
    this.model= this.serverService.getdata();
    this.TeamName= this.model.EmpFullName;
  this.TeamTitle = this.model.EmpTitle;
  this.role = this.model.RoleResponsiblity;
  this.imgURL=this.model.ImagePath
  }

}
