import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// const API_URL:'https://api.ubsols.com/ServiceUbsols.svc';

import { environment } from 'src/environments/environment';

 const API_URL=environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  blogdatatoedit: any;
  archievejobedit: any;
  teamdataedit: any;


  constructor(private http: HttpClient) { }
  
   // Admin login 
   Login(data) { 
    // console.log('data', data);
    return this.http.post(API_URL + '/Login', data); 
  }

  // Service to post article 
ArticlePosting(data, fileStream){
  const name= data.fileName;
  const id = data.AccountId;
  const mid = data.MemberId;
  const articletitle = data.ArticleTitle;
  const Type=data.ArticleType;
  const description= data.ArticleDescription;
 
    return this.http.post<any[]>(API_URL +'/ArticlePosting?fileName='+name+'&'+
    'AccountId='+id+'&MemberId='+mid+'&ArticleTitle='+articletitle+'&ArticleType='+Type+'&'+
    'ArticleDescription='+description+'', fileStream );
 
 }
 
 //Service to Fetch Articles
 FetchArticles(data){
 
     return this.http.post<any[]>(API_URL +'/FetchArticles', data );
 }
 
 ModifyArticle(data, filestream) {
  debugger
      return this.http.post<any[]>(API_URL + '/ModifyArticle?fileName=' + data.fileName + '&' +
      'ArticleId='+ data.ArticleId + '&MemberId=' + data.MemberId + '&ArticleTitle=' + data.ArticleTitle + '&ArticleDescription=' + data.ArticleDescription, filestream);
    }
  
// Service to Delete article 
DeleteArticle(data){
 
  return this.http.post<any[]>(API_URL +'/RemoveArticle', data );

}

 // Service to post team 
// ...OUR TEAM  services start--------------------

// Service to post article

AddOurTeam(data, fileStream){
  const name= data.fileName;
  const id = data.AccountId;
  const Fname = data.EmpFirstName;
  const Lname = data.EmpLastName;
  const Etitle=data.EmpTitle;
  const Erole= data.RoleResponsiblity;
 
    return this.http.post<any[]>(API_URL +'/AddOurTeam?fileName='+name+'&'+
    'AccountId='+id+'&EmpFirstName='+Fname+'&EmpLastName='+Lname+'&EmpTitle='+Etitle+'&'+
    'RoleResponsiblity='+Erole+'', fileStream );
 }

 //Service to Fetch Our Team
FetchOurTeam(data){
  return this.http.post<any[]>(API_URL +'/FetchOurTeam', data );
}

ModifyOurTeam(data, fileStream ) {
  debugger
      return this.http.post<any[]>(API_URL + '/ModifyOurTeam?fileName=' + data.fileName + '&' +
      'EmployeeId=' + data.EmployeeId +'&EmpTitle='+ data.EmpTitle+ '&RoleResponsiblity='+ data.RoleResponsiblity , fileStream);
    }
// Service to Delete article 
DeleteTeam(data){
 
  return this.http.post<any[]>(API_URL +'/RemoveOurTeam', data );

}
setteam(data){
  this.teamdataedit={};
  this.teamdataedit =data;

}
getteam(){
 
  let temp = this.teamdataedit ;

  return temp
}

//---------------------Gallery Start--------------------

// Service to post Gallery 
AddGallery(data, fileStream){
  const name= data.fileName;
  const id = data.AccountId;
  const imgName= data.ImageName;
 
    return this.http.post<any[]>(API_URL +'/AddGallery?fileName='+name+'&'+
    'AccountId='+id+'&ImageName='+imgName+'', fileStream );
 
 }
 //Service to Fetch Gallery
FetchGallery(data){

  return this.http.post<any[]>(API_URL +'/FetchGallery', data );
}
// Service to Delete Gallery 
RemoveGallery(data){
 
  return this.http.post<any[]>(API_URL +'/RemoveGallery', data );

}

// TO share data between components for editing setdata() and getdata()
setdata(data){
  
  this.blogdatatoedit={};
  this.blogdatatoedit =data;

}
getdata(){
  //debugger;
  let temp = this.blogdatatoedit ;
  return temp
}

// Service to save contactus detail and send to email
SendEmail(data){
  return this.http.post<any[]>(API_URL +'/SendEmail' , data );
}

//FetchSpecialization
FetchSpecialization(data){
  return this.http.post<any[]>(API_URL +'/FetchSpecialization', data );
  }
  
  //Resume posting method
  ResumePosting(data, fileStream){
    
    return this.http.post<any[]>(API_URL +'/ResumePosting?fileName='+data.fileName+'&'+
    'FirstName='+data.FirstName+'&LastName='+data.LastName+'&PhoneNumber='+data.PhoneNumber+'&ZipCode='+data.ZipCode+'&'+
    'EmailAddress='+data.EmailAddress+'&Specialized='+data.Specialized, fileStream ); 
  }

  //Archieve jobs Service start

  // Service to post Archive job 
AddArchiveJob(data){
 
  return this.http.post<any[]>(API_URL +'/AddArchiveJobs', data);

}

//Service to Fetch Archive Job
FetchArchiveJobs(data){

   return this.http.post<any[]>(API_URL +'/FetchArchiveJobs', data );
}

// Modify archive job
ModifyArchiveJob(data){
  
 return this.http.post<any[]>(API_URL +'/ModifyArchiveJobs', data );
}

// Service to Delete article 
DeleteArchiveJob(data){

 return this.http.post<any[]>(API_URL +'/RemoveArchiveJobs', data );

}

///Service to add Job title

AddJobTitle(ajt){
 
  return this.http.post<any[]>(API_URL +'/AddJobTitle', ajt);

}

FetchJobTitleList(){
  return this.http.get<any[]>(API_URL +'/FetchJobTitle');
}
DeleteJobTitle(djt){
  return this.http.post<any[]>(API_URL +'/RemoveJobTitle', djt);
}
ModifyJobTitle(mjt){
  return this.http.post<any[]>(API_URL +'/ModifyJobTitle', mjt);
}
}
