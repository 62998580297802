import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/shared/services/server.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-newsdetails',
  templateUrl: './newsdetails.component.html',
  styleUrls: ['./newsdetails.component.css']
})
export class NewsdetailsComponent implements OnInit {

  model: any ={};
  NewsList: any;

  imgURL: any;
  newsTitle: any;
  nodata= false;
  newsId:any;
  newsDescription:any;
  QueryParamId: string;
  NewsData: any;
  private unsubscribe$ = new Subject<void>();
  newsDate: any;
    constructor(private serverService: ServerService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    
    // this.viewNews();
    // this.FetchNewsList();
    this.route.queryParamMap.subscribe((URLparams: any) => {
      const EncodeURL = URLparams.params.q;
      this.QueryParamId = atob(EncodeURL);
    // console.log(this.QueryParamId);
   }); 
   this.viewNewsBYURL(this.QueryParamId);
   this.FetchNewsList(this.QueryParamId);
  }
  viewNews(){  
    this.model= this.serverService.getdata();
   
    this.newsId=this.model.ArticleId;
      this.newsTitle = this.model.ArticleTitle;
    this.newsDescription = this.model.ArticleDescription;
    this.imgURL=this.model.ImagePath
    this.serverService.blogdatatoedit=undefined;    
  }

  viewNewsBYURL(Id){
    this.model.AccountId=2
        this.serverService.FetchArticles(this.model)
       .pipe(takeUntil(this.unsubscribe$))    
        .subscribe(
          (data : any) =>{
           // console.log(Id);
           
            this.NewsData = data.ArticleList.filter(element => {
             // console.log(element.ArticleId);
              return element.ArticleId === parseInt(Id) ;
            });
           // console.log(this.NewsData);
           this.newsId=this.NewsData[0].ArticleId;
          // console.log(this.BlogData[0]);
            this.newsTitle = this.NewsData[0].ArticleTitle;
          this.newsDescription = this.NewsData[0].ArticleDescription;
          this.imgURL=this.NewsData[0].ImagePath;
          this.newsDate = this.NewsData[0].ArticleDate
          });
       
     }

  FetchNewsList(Id){
    this.model.AccountId=2
    this.serverService.FetchArticles(this.model)    
    .subscribe(
      (data:any )=>{ 
        this.NewsList={};
        // this.BlogList=data.ArticleList;
        const filterValue = "NEWS";
        const result = data.ArticleList.filter(element => {
          return element.ArticleType === filterValue;
        });
       
              this.NewsList =result;     
              let index = this.NewsList.findIndex(d => d.ArticleId ===  parseInt(Id)); //find index in your array
              this.NewsList.splice(index, 1);//remove element from array
              if(this.NewsList.length==0){
                      this.nodata= true;
                    } else {
                      this.nodata = false;
                    }
   
     } );

  }
  // OpenNewsToLeftSide(news){
  //   this.redirect();
  //   this.serverService.setdata(news);
  //  this.viewNews();
  //  this.FetchNewsList(this.newsId);
  // }

  OpenNewsToLeftSide(news){
    const EncodeURL = btoa(news.ArticleId);
  this.router.navigate(['/']).then(() => { this.router.navigate(['/newsdetails' ], { queryParams: { q: EncodeURL } }); })
  }
  redirect() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

}
