<div class="btco-hover-menu navcls2">
  <!-- <nav class="navbar navbar-expand-md navbar-expand-lg affix preview__header mobilenavhight" data-spy="affix" data-offset-top="197"> -->
  <nav class="navbar navbar-expand-lg affix topHeader " aria-label="header" data-spy="affix" data-offset-top="197">
    <div class="container">
      <div>
        <a class="navbar-brand image-padding" routerLink="/home" (click)="redirect()"><img src="assets/images/spino_logo.png"
            class="img-fluid" alt="logo"></a>
            <a class="email-font text-tranform"  (click)="mobilenav()" >
              Info@spinoinc.com</a>
      </div>
      <button class="navbar-toggler topmob-nav" type="button" data-toggle="collapse" data-target="#topnavmobile1"
        aria-controls="topnavmobile1" aria-expanded="false" aria-label="Toggle navigation" (click)="checkMob()">
        <span class="fa fa-bars"></span>
      </button>

      <!-- <li class="nav-item nav-link">  <a routerLink="/home"><img src="assets/images/spino_logo.png" (click)="redirect()" class="img-fluid" alt="logo"></a> </li> -->
      <div class="collapse navbar-collapse navfont" id="topnavmobile1">
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item">
             <a class="nav-link"  aria-haspopup="true" (click)="mobilenav()" >
               <p class="mail carr">Info@spinoinc.com</p>
              </a> </li>
          <li class="nav-item"> <a class="nav-link  pad"  aria-haspopup="true" (click)="mobilenav()" routerLink="/archivejobs"><b><i class="fa fa-briefcase" aria-hidden="true"></i> Archive Jobs</b></a> </li>
          <li class="nav-item"> <a class="nav-link" (click)="mobilenav()"  aria-haspopup="true"  href="https://www.ewts.spinoinc.com" target="_blank"><b><i class="fa fa-line-chart" aria-hidden="true"></i> Task Manager</b></a> </li>
        -->

          <li class="nav-item nav-link">

          </li>

          <li class="nav-item nav-link">
            <a class=" font" routerLink="/archivejobs" (click)="mobilenav()">
              <b><i class="fa fa-archive" aria-hidden="true"></i> Current Jobs</b></a>
          </li>


          <li class="nav-item nav-link">
            <a class=" font" (click)="mobilenav()" href="https://ewts.spinoinc.com/" target="_blank">
              <b><i class="fa fa-line-chart" aria-hidden="true"></i> Task Manager</b></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <nav class="navbar navbar-expand-lg affix ">
    <div class="container">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#topnavmobile"
        aria-controls="topnavmobile" aria-expanded="false" (click)="checkMob2()" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse navfont" id="topnavmobile" (click)="removeactive()">
        <ul class="navbar-nav active" (click)="removeactive()">
          <li class="nav-item" id="removeactive"> <a class="nav-link" aria-haspopup="true" routerLink="/home"
              (click)="redirect()">Home</a> </li>
          <li class="nav-item"> <a class="nav-link" routerLink="/about" (click)="redirect()">About</a> </li>
          <li class="nav-item"> <a class="nav-link" routerLink="/services" (click)="redirect()">Services</a> </li>
          <li class="nav-item"> <a class="nav-link" routerLink="/consulting" (click)="redirect()">Consulting</a> </li>
          <li class="nav-item"> <a class="nav-link" routerLink="/clients" (click)="redirect()">Clients</a> </li>
          <li class="nav-item"> <a class="nav-link" routerLink="/careers" (click)="redirect()">Careers</a> </li>
          <li class="nav-item"> <a class="nav-link" routerLink="/blog" (click)="redirect()">Blog</a> </li>
          <li class="nav-item"> <a class="nav-link" routerLink="/news" (click)="redirect()">News</a> </li>
          <li class="nav-item"> <a class="nav-link" routerLink="/ourteam" (click)="redirect()">Our Team</a> </li>
          <li class="nav-item"> <a class="nav-link" routerLink="/gallery" (click)="redirect()">Gallery</a> </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
              aria-expanded="false">Our Products</a>
            <div class="dropdown-menu">
              <a class="dropdown-item font" routerLink="/token" (click)="redirect()">Token Management System</a>
              <a class="dropdown-item font" routerLink="/visitor" (click)="redirect()">Visitor Management System</a>
              <a class="dropdown-item font" routerLink="/itsmyturn" (click)="redirect()">ItsMyTurn</a>
            </div>
          </li>
          <li class="nav-item"> <a class="nav-link" routerLink="/contact" (click)="redirect()">Contact</a> </li>

        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item"> <a class="nav-link btn btn-primary bg-color" (click)="redirect()" routerLink="/login">Login</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>