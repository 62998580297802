import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-its-myturn',
  templateUrl: './its-myturn.component.html',
  styleUrls: ['./its-myturn.component.css']
})
export class ItsMyturnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
