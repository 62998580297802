import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/shared/services/server.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  model: any ={};
  BlogList: any;
  currentYear: number;
    constructor(private serverService: ServerService, private router:Router) { }

    ngOnInit(): void {
      this.FetchBlogList();
      this.currentYear = new Date().getFullYear();
   }
   FetchBlogList(){
    this.model.AccountId=2
    
    this.serverService.FetchArticles(this.model)    
    .subscribe(
   (data:any )=>{ 
        //console.log(data);
      
      this.BlogList={};
      // this.BlogList=data.ArticleList;
      const filterValue = "BLOG";
      const result = data.ArticleList.filter(element => {
        return element.ArticleType === filterValue;
      });
            this.BlogList =result;
   } );

  }

  Viewblog(blog){
    this.serverService.setdata(blog);
   // this.router.navigateByUrl("/blogdetails")
   const EncodeURL = btoa(blog.ArticleId);
      // console.log(EncodeURL);
      
      this.router.navigate(['/']).then(() => { this.router.navigate(['/blogdetails' ], { queryParams: { q: EncodeURL } }); })
    // this.router.navigate(["/blogdetails"], { queryParams: { q: EncodeURL } });
 }
  redirect(){
    window.scrollTo(0,0);
    }

}
