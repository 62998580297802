import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from 'src/app/shared/services/server.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})
export class NewsListComponent implements OnInit , OnDestroy {

  model: any ={};
  newsedit: any;
  NewsList: any;
  Fail:boolean;
  Success:boolean;
  nodata = false;
  ArticleId: any;
  successMessage:any;
  errorMessage:any;
  private unsubscribe$ = new Subject<void>();
  constructor(private serverService: ServerService,private router:Router,) { }

  ngOnInit() {
    this.FetchNewsList();
  }

  FetchNewsList(){
    this.model.AccountId=2
    
    this.serverService.FetchArticles(this.model)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (data:any )=>{ 
      
         // console.log(data);
         
         this.NewsList={};
         // this.BlogList=data.ArticleList;
         const filterValue = "NEWS";
         const result = data.ArticleList.filter(element => {
           return element.ArticleType === filterValue;
         });
         
         this.NewsList =result;
         if( this.NewsList=="" || this.NewsList.length==0){
                   this.nodata=true;
                 }
                 else{
                   this.nodata=false;
                 }
      } );

  }
    //to edit and update blog
    upadteNews(user){
      this.serverService.setdata(user);
      this.router.navigateByUrl('/crudnews')
      
        }
    
        DeleteNews(ArticleID , Status){
          // alert("Are you sure you want to Delete");
         this.model={};
          this.model.ArticleId=ArticleID;
          if(Status==='Yes'){
          this.serverService.DeleteArticle( this.model).subscribe(
            res=>{
              // console.log(res)
               if(res['Response'].status ==200){ 
                this.Success =true;
               this.successMessage= res['ArticleData'].ReturnVal;
               setTimeout(() => {
                this.Success = false;
                this.successMessage = '';
                        }, 3000);
               }
               else{
                 this.Fail=true;
                 this.errorMessage= 'Error while processing';
                 setTimeout(() => {
                  this.Fail = false;
                  this.errorMessage = '';
                          }, 3000);
               }
              }
       
            
          );
            }
          this.FetchNewsList();
          this.router.navigateByUrl('/newslist')
        }

  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
