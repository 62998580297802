<header class="innner-page">
  <div class="container">
    <h1><i class="fa fa-angle-double-right" aria-hidden="true"></i> Add Gallery</h1>
  </div>
</header>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb no-gutters">
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="Dashboard" routerLink="/dashboard" skipLocationChange>Home
      </a>
    </li>
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="News" routerLink="/gallerylist" skipLocationChange>Gallery List
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Add Gallery
    </li>
  </ol>
</nav>
<section class="section-padding-ash">
<div class="container" role="main">
  <div class="alert alert-success" *ngIf="Success">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    <p>{{ successMessage }}</p>
  </div>
  <div class="col-md-12">
  <form #f="ngForm" aria-label="form"  name="form" class="pure-form pure-form-stacked formborder"  (ngSubmit)="f.form.valid && onSubmit(f)"
  novalidate>
  <div class="col-md-10">
    <span class="h6">Image Name :</span>
  <div class="form-group">
    <input type="text" class="form-control" name="imgname" [(ngModel)]="model.imgname" #imgname="ngModel"
      placeholder="Enter Image Name" [ngClass]="{ 'is-invalid': f.submitted && imgname.invalid }" required />
    <div *ngIf="(imgname.touched ||f.submitted) && imgname.invalid" class="invalid-feedback">
      <div *ngIf="imgname.errors.required">Enter Image Name</div>
    </div>
  </div>
  </div>
  <div class="col-md-8">
  <div class="form-group">
      <input *ngIf="!imgURL "  aria-label="img" type="file" name="image" (change)="fileChangeEvent($event)" accept="image/*"
      #fileInput>
    <div>
      <img *ngIf="imgURL" [src]="imgURL" alt="No Image" height="100" width='100' />
    </div>
    <div *ngIf="f.submitted && !imgURL" style="color:#dc3545; font-size: 12.8px;">Please select image</div>
  </div>
</div>
<div class="col-md-8 padbtm">
  <button type="submit" class="col-md-3 btn btn-primary">Submit</button>
  <button type="reset" class="col-md-3 btn btn-cancel" (click)="CancelGallery()">Cancel</button>
</div>
</form>
</div>
</div>
</section>
