<div class="preview__header" role="contentinfo" >
  <div class="preview__actions">
    <div class="preview__action">
      <a class="navbar-brand pad"  routerLink="/dashboard"><img src="assets/images/spino_logo.png" class="img-fluid"
          alt="logo"></a>

    </div>
    <div class="preview__action">
    </div>
  </div>
</div>
<nav class="navbar navbar-expand-lg affix ">
  <div class="container">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"> <span
        class="navbar-toggler-icon"></span> </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item"> <a class="nav-link" routerLink="/bloglist" (click)="redirect()">Blog</a> </li>
        <li class="nav-item"> <a class="nav-link" routerLink="/newslist" (click)="redirect()">News</a> </li>
        <li class="nav-item"> <a class="nav-link" routerLink="/ourteamlist" (click)="redirect()">Our Team</a> </li>
        <li class="nav-item"> <a class="nav-link" routerLink="/gallerylist" (click)="redirect()">Gallery</a> </li>
        <li class="nav-item"> <a class="nav-link" routerLink="/archivejoblist" (click)="redirect()">Archive Jobs</a>
        </li>
        <li class="nav-item"> <a class="nav-link" routerLink="/addtitle" (click)="redirect()">Add Title</a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto" style="padding-right: 20px;">
        <li class="nav-item"> <a class="btn btn-primary" (click)="logout()">Logout</a></li>
      </ul>
    </div>
  </div>
</nav>