
<div role="main">


<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/news.jpg" alt="First slide">
      </div>
    </div>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">News List
      </li>
    </ol>
  </nav>
  <section class="section-padding-ash">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="nodata==false">
          <div class="row">
           <!-- Spinner start -->
           <ngx-spinner
           bdColor="rgba(51,51,51,0.8)"
           size="default"
           color="#fd6121"
           type="line-scale-pulse-out-rapid"
           [fullScreen] = "false" > </ngx-spinner>
            <!-- Spinner end -->
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"  *ngFor="let news of NewsList; let i = index">
              <div class="blog-post-cont"><a class="cursor" (click)="ViewNews(news)"> <img [src]="news.ImagePath" height="180px;" width="350px;" alt="News Image"></a>
                <div class="text-cont">
                  <h1><a>SpinoInc</a> | <a (click)="ViewNews(news)">{{news.ArticleDate}}</a></h1>
                  <div class="h5"><a  (click)="ViewNews(news)">{{news.ArticleTitle| slice:0:30}}..</a></div>
                  <p class="Description just">{{news.ArticleDescription}}</p>
                  <a  class="readdmore" (click)="ViewNews(news)">Read More <i class="icofont icofont-long-arrow-right"></i></a> </div>
              </div>
            </div>
          </div>
        </div><div  *ngIf="nodata==true" >
          <h6 class="Nodata"> No News Found</h6>
          </div>
      </div>
    </div>
  </section>
</div>