import { Component, OnInit, ViewChild } from '@angular/core';
import { ImagevalidationService } from 'src/app/shared/services/imagevalidation.service';
import { ServerService } from 'src/app/shared/services/server.service';
import { GalleryListComponent } from '../gallery-list/gallery-list.component';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ImageCropperComponent } from '../image-cropper/component/image-cropper.component';
import { ImageTransform, ImageCroppedEvent } from '../image-cropper/interfaces';

@Component({
  selector: 'app-crud-gallery',
  templateUrl: './crud-gallery.component.html',
  styleUrls: ['./crud-gallery.component.css']
})
export class CrudGalleryComponent implements OnInit {


  model: any ={};
   NewsList: any;
   Gallerymodel: any ={};
   title : string;
   Success:any;
   Fail: any;
   errorMessage: any;
   successMessage: any;
   description : string;
   blob;
   filestream: any;
   ImgValidate: any;
   imageChangedEvent: any = '';
   selectedFile: File = null;
 // example
   public imagePath;
   imgURL: any;
   croppedImage: any = '';
   scale = 1;
   showCropper = false;
   canvasRotation = 0;
   rotation = 0;
 transform: ImageTransform = {};
  @ViewChild('f') formValues;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

    constructor( private imagevalidation: ImagevalidationService,
      private serverService: ServerService, private router:Router) { }

  ngOnInit(): void {
  }

     // method for select file
     fileChangeEvent(event: any): void {
      try {
        
        // image validation will be get done here
        this.ImgValidate = this.imagevalidation.ValidateImage(event);
        if (this.ImgValidate === true) {
  
          this.imageChangedEvent = event;
          // tslint:disable-next-line
          this.selectedFile = event.target.files[0];
          var reader = new FileReader();
          this.imagePath = event.target.fiiles;
          reader.readAsDataURL(event.target.files[0]); 
          // reader.onload = (event: ProgressEvent) => {
          //   this.imgURL = (<FileReader>event.target).result;
   
          // };
      
          reader.onload = (_event) => { 
          
            this.imgURL = reader.result; 
            
             fetch(this.imgURL)
             .then(res => res.blob())
            .then(blob => {
             const fd = new FormData();
               fd.append('image', blob, 'filename');
               this.blob = blob;
             });
            }
     
  
        } else {
           this.Fail = true;
           this.errorMessage = this.ImgValidate;
          setTimeout(() => {
            this.Fail = false;
            this.errorMessage = '';
          }, 3000);
        }
      } catch (error) {
        throw error;
      }
  
    }
    CancelGallery() {

      this.formValues.resetForm();
  
      this.imgURL = '';
  
      this.router.navigateByUrl('/gallerylist')
    }
  
    onimageload() {
      try{
        // this.updateResult = {};
        const fileData: any = {};
           
        fileData.data = this.selectedFile['name'];
        this.blob.name =  fileData.data
        this.blob.webkitRelativePath = '';
        fileData.fileStream = this.blob;
       
  return fileData.fileStream}
catch{}  
    }

    onSubmit(f:NgForm) {
      
      const data = this.model.ArticleId
      if(data==undefined){
           const fileData:any ={};
          this.Gallerymodel.ImageName=this.model.imgname;
          fileData.fileStream =this.onimageload();
          //this.Blogmodel.fileStream='';
        this.Gallerymodel.fileName= this.selectedFile['name'];
        this.Gallerymodel.AccountId=2;
       
          this.serverService.AddGallery(this.Gallerymodel, fileData.fileStream).subscribe(
            res=>{
              if(res['Response'].status==200)
              {
               
                this.Success=true;
                this.successMessage=res['GalleryData'].ReturnVal;
                setTimeout(() => {
                  this.Success = false;
                  this.successMessage = '';
                          }, 3000);
                          this.router.navigateByUrl('/gallerylist',  { skipLocationChange: true })          
              }
              else{
                this.Fail=true;
                this.errorMessage=res['GalleryData'].ReturnVal;
                setTimeout(() => {
                  this.Fail = false;
                  this.errorMessage = '';
                          }, 3000);
              }
      
             
            });
            this.formValues.resetForm();
          
          this.imgURL='';
          //this.Comp.FetchNewsList();
          }
          else{
            const obj:any = {};
             obj.ArticleId= this.model.ArticleId;
            obj.ImageName= this.model.imgname;
            const fileData:any ={};
            fileData.fileStream=''
            this.serverService.ModifyArticle(obj, fileData.fileStream).subscribe(
               res =>{
               if(res['Response'].status==200)
            {
             
              this.Success=true;
              this.successMessage=res['GalleryData'].ReturnVal;
              setTimeout(() => {
                this.Success = false;
                this.successMessage = '';
                        }, 3000);
            }
            else{
              this.Fail=true;
              this.errorMessage=res['GalleryData'].ReturnVal;
              setTimeout(() => {
                this.Fail = false;
                this.errorMessage = '';
                        }, 3000);
            }
      
            });
            this.formValues.resetForm();
          
          this.imgURL='';
          //this.Comp.FetchNewsList();
         
            this.router.navigateByUrl('/gallerylist',  { skipLocationChange: true })
          }
          
        }
}
