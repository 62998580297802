<div role="main">

  <div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/consulting.png"
          alt="First slide">
      </div>
    </div>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Consulting
      </li>
    </ol>
  </nav>
  <section class="section-padding-ash">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="blog-post-cont"> <img src="assets/images/consultingCont.jpg" class="img-fluid" alt="item 1">
                <div class="blog-post-cont">
                  <section class="sidepad">
                    <div class="container ">
                      <div class="row">
                        <div class="col-lg-12">
                          <div id="accordion">
                            <div class="card">
                              <div class="card-header btn-link cursor" id="headingOne" data-toggle="collapse"
                                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="mb-0">
                                  <a class="btn h5heading">
                                    Company
                                  </a>
                                </span>
                              </div>

                              <div id="collapseOne" class="collapse show" 
                                data-parent="#accordion">
                                <div class="card-body just">
                                  Spinoinc is a leading provider of staffing, consulting, and solutions in the
                                  disciplines of information technology, finance, pharmaceutical and healthcare.
                                  Spinoinc delivers its services to government entities and businesses in virtually all
                                  industries throughout the United States & India. We deliver business solutions for the
                                  unique requirements of each individual client. Spinoinc was started with an objective
                                  of assisting clients in achieving mission critical business objectives with
                                  state-of-the-art technologies and maximize their ability to deliver value to their
                                  customers, business value chain partners.

                                  The team at Spinoinc is highly skilled and has prior working experience in leading
                                  organizations. Our Services include Staffing,Consulting,Development for small and
                                  medium enterprises.
                                </div>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header btn-link cursor" id="headingTwo" data-toggle="collapse"
                                data-target="#collapseTwo" aria-expanded="true" >
                                <span class="mb-0">
                                  <a class="btn h5heading">
                                    Staffing
                                  </a>
                                </span>
                              </div>
                              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#accordion">
                                <div class="card-body">
                                  <ul>
                                    <li>Spinoinc is a leading provider of staffing, consulting,and solutions.</li>
                                    <li> Recruitment</li>
                                    <li> OPT HIRING</li>
                                    <li>CPT HIRING</li>
                                    <li>H1B Placement Consultancy</li>
                                    <li>H1B Sales</li>
                                    <li>Corp to Corp</li>
                                    <li> Direct Vendor placement</li>
                                    <li>Contract Hire</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header btn-link cursor" id="headingThree" data-toggle="collapse"
                                data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                <span class="mb-0">
                                  <a class="btn h5heading">
                                    Support
                                  </a>
                                </span>
                              </div>
                              <div id="collapseThree" class="collapse" 
                                data-parent="#accordion">
                                <div class="card-body">
                                  <ul>
                                    <li class="just">Customer support teams are spread throughout the regions to ensure
                                      our customers don’t experience downtime and meet the ever increasing demands of
                                      our customers. We have support structures that go up to 24 hours a day 365 days a
                                      year..</li>
                                    <li><b>Online Training</b></li>
                                    <li><b> On Job Support</b></li>
                                    <li><b>Video Tutorials</b></li>
                                    <li><b>E-books</b></li>
                                    <li><b>Real time projects over view</b></li>
                                    <li><b>Demonstration about the position</b></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header btn-link cursor" id="headingFour" data-toggle="collapse"
                                data-target="#collapsefour" aria-expanded="true" aria-controls="collapsefour">
                                <span class="mb-0">
                                  <a class="btn h5heading">
                                    Service
                                  </a>
                                </span>
                              </div>
                              <div id="collapsefour" class="collapse" 
                                data-parent="#accordion">
                                <div class="card-body just">
                                  Spino INC has the most extensive service offering from highly talented personnel
                                  across 2 countries. We do not outsource any aspect of our services, therefore
                                  guarantee to ensure timely delivery and accuracy whilst remaining within budgets. Our
                                  services include;
                                  Engineers for Onsite Attendance
                                  Software Developers for Configuration and Bespoke work
                                  Web Designers to give you the cutting edge designs
                                  Consultants to ensure you get the best of breed solutions
                                  Project management teams to keep you happy
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div class="notification">
                    <div class="alert alert-success" *ngIf="Success">
                      <button type="button" class="close" data-dismiss="alert">&times;</button>
                      <p>{{ successMessage }}</p>
                    </div>
                    <div class="alert alert-danger" *ngIf="Fail">
                      <button type="button" class="close" data-dismiss="alert">&times;</button>
                      <p>{{ errorMessage }}</p>
                    </div>
                  </div>
                  <span class="sidepad">Upload Resume:</span>

                  <form name="form" (ngSubmit)="f.form.valid && onSubmit(f)" #f="ngForm" novalidate id="ContactForm">
                    <div class="row  sidepad">
                      <div class="col-md-12">
                        <div class="form-row underline">
                          <div class="form-group" class="upload-btn-wrapper ">
                            <!-- <div class="choose">Step 1: Choose File : </div> -->
                            <div class="choose">

                              <span class="border mxwd">Choose File:</span>
                            </div>
                          </div>
                          <div class="form-group" class="upload-btn-wrapper col-md-6 mxwd1">
                            <span class="file-wrapper">
                              <input type="file" name="resume" id="resume" (change)="fileChangeEvent($event)"
                                accept="application/msword,application/pdf" aria-label="resume" #fileInput>
                              <div class="button"><i class="fa fa-upload"> UPLOAD YOUR RESUME</i></div>
                            </span>
                            <span>{{ fileInfo }}</span>
                          </div>
                          <div *ngIf="f.submitted && !fileInfo" class="col-md-6 fileValidation">Please upload resume
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input type="text" class="form-control" placeholder="First Name *" name="firstName"
                            [(ngModel)]="model.firstName" #firstName="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }" required />
                          <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                            <div *ngIf="firstName.errors.required">First Name is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input type="text" class="form-control" placeholder="Last Name *" name="lastName"
                            [(ngModel)]="model.lastName" #lastName="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && lastName.invalid }" required />
                          <div *ngIf="f.submitted && lastName.invalid" class="invalid-feedback">
                            <div *ngIf="lastName.errors.required">Last Name is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input type="text" class="form-control" placeholder="Phone Number*" name="phoneNumber"
                            [(ngModel)]="model.phoneNumber" pattern="[0-9]{10}" #phoneNumber="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && phoneNumber.invalid }" required minlength="10"
                            maxlength="10" />
                          <div *ngIf="f.submitted && phoneNumber.invalid" class="invalid-feedback">
                            <div *ngIf="phoneNumber.errors.required">Phone Number is required</div>
                            <div *ngIf="(phoneNumber.errors.minlength || phoneNumber.errors.pattern)">Phone Number must
                              be
                              valid</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input type="text" class="form-control" placeholder="Zip code*" name="zipCode"
                            [(ngModel)]="model.zipCode" #zipCode="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && zipCode.invalid }" required minlength="5"
                            maxlength="5" />
                          <div *ngIf="f.submitted && zipCode.invalid" class="invalid-feedback">
                            <div *ngIf="zipCode.errors.required">Zip Code is required</div>
                            <div *ngIf="zipCode.errors.minlength">Zip Code must be 5 characters</div>
                            <div *ngIf="zipCode.errors.maxlength">Zip Code must be 5 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input type="text" class="form-control" placeholder="Email*" name="email"
                            [(ngModel)]="model.email" #email="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email />
                          <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                            <div *ngIf="email.errors.required">Email is required</div>
                            <div *ngIf="email.errors.email">Email must be a valid email address</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <select class="form-control" placeholder="Specialized Service*" name="Specialized"
                            [(ngModel)]="model.Specialized" #Specialized="ngModel" aria-label="Specialized"
                            [ngClass]="{ 'is-invalid': f.submitted && Specialized.invalid }" required>
                            <option [ngValue]="undefined">
                              <p class="place">---Select---</p>
                            </option>
                            <option *ngFor="let item of SpecializationList" [value]="item.Speciality">
                              {{item.Speciality}}
                            </option>
                          </select>
                          <div *ngIf="f.submitted && Specialized.invalid" class="invalid-feedback">
                            <div *ngIf="Specialized.errors.required">Specialization is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <p>By submitting this form, you are agreeing to our terms of use.</p>
                        </div>
                      </div>
                      <div class="col-md-12 subpad">
                        <button type="submit" class="btn btn-primary">Submit</button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <div class="sidebar">
            <div class="sidebar-item" style="float: left;">
              <h1>Recent News</h1>
              <hr />
              <ul *ngFor="let news of NewsList| slice:0:5">
                <li class="list-style"><a class="latest-blog-single-img "><img [src]="news.ImagePath" class="mobileimg"
                      alt="SpinoInc Image"></a></li>
                <li><a class="cursor" (click)="ViewNews(news)">
                    <p class="leftmenu">{{news.ArticleTitle| slice:0:50}}..</p>
                  </a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>