<div role="main">
<script type="text/javascript" src="https://api.ceipal.com/iframe.js"></script>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Careers
  </ol>
</nav>
<section>
  <body>
      <!-- Spinner start -->
      <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="default"
      color="#fd6121"
      type="line-scale-pulse-out-rapid"
      [fullScreen] = "false" > </ngx-spinner>
       <!-- Spinner end -->
    <div class="jobcodes">
        <iframe id="careers_api_source" width="100%" height="2500"
         iframeborder="0" style="border:none; margin:0px;" src="careers_v1/index.html"> 
        </iframe>
      </div>
  </body>
</section>
</div>