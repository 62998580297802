<header class="innner-page">
  <div class="container">
    <h1><i class="fa fa-angle-double-right" aria-hidden="true"></i>Upload Resume</h1>
    <!-- <h1><i class="fa fa-angle-double-right" aria-hidden="true"></i> Services</h1> -->
  </div>
</header>

<div class="loading" style="display: none;">
  <div class="loader"></div>
</div>
<section class="contact-map">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">


        <div class="padding">


          <div class="wizard" style="align-content: center;">
            <!-- <h1 class="marginTop">Upload Resume</h1> -->
            <form name="form" (ngSubmit)="f.form.valid && onSubmit(f)" #f="ngForm" novalidate>
              <div class="marginTop ">
                <div class="form-row underline">
                  <div class="form-group" class="upload-btn-wrapper ">
                    <!-- <div class="choose">Step 1: Choose File : </div> -->
                    <div class="choose">

                      <h3 class="border">Step 1: Choose File :</h3>
                    </div>
                    <div *ngIf="f.submitted && !fileInfo" class="fileValidation" style="height: 20px;"></div>

                  </div>
                  <div class="form-group" class="upload-btn-wrapper">
                    <!-- <span class="file-wrapper">
                      <input type="file" name="photo" id="photo" />
                      <div class="button">Upload</div>
                    </span> -->
                    <!-- <div class="btn">
                      <i class="fa fa-file-alt "> <span class="cursor" *ngIf="!fileInfo">
                          Upload Your Resume</span></i>
                      <input type="file" name="resume" id= "resume" (change)="fileChangeEvent($event)"
                        accept="application/msword,application/pdf" #fileInput>
                    </div> -->
                    <span class="file-wrapper">
                     <input type="file" name="resume" id= "resume" (change)="fileChangeEvent($event)"
                        accept="application/msword,application/pdf" #fileInput>
                      <div class="button"><i class="fa fa-upload "> Upload Your Resume</i></div>
                    
                    </span>
                    
                    <!-- <input type="file" name="myfile" /> -->
                    <span>{{ fileInfo }}</span>
                    <span>  <div *ngIf="f.submitted && !fileInfo" class="fileValidation">Please upload resume</div></span>

                    <!-- <div *ngIf="resume" style="color: red;">Select Resume</div>
                    <div *ngIf="!resume" style="color: greenyellow;">Resume Selected</div> -->

                  </div>
                </div>
                <h3 class="marginTop border">Step 2: Add Contact Information</h3>
                <div class="form-content marginTop">
                  <div class="form-row">
                    <div class="form-group">
                      <label class="form-label">First Name</label>
                      <!-- <input type="text" value="" class="form-field" /> -->
                      <input type="text" class="form-field" name="firstName" [(ngModel)]="model.firstName"
                        #firstName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }" required />
                      <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                        <div *ngIf="firstName.errors.required">First Name is required</div>
                      </div>
                      <!--<label>Error</label>-->
                    </div>
                    <div class="form-group">
                      <label class="form-label">Last Name</label>
                      <!-- <input type="text" value="" class="form-field" /> -->
                      <input type="text" class="form-field" name="lastName" [(ngModel)]="model.lastName"
                        #lastName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastName.invalid }" required />
                      <div *ngIf="f.submitted && lastName.invalid" class="invalid-feedback">
                        <div *ngIf="lastName.errors.required">Last Name is required</div>
                      </div>
                      <!--<label>Error</label>-->
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group">
                      <label class="form-label">Phone Number</label>
                      <!-- <input type="text" value="" class="form-field" /> -->
                      <input type="text" class="form-field" name="phoneNumber" [(ngModel)]="model.phoneNumber"
                        pattern="[0-9]{10}" #phoneNumber="ngModel"
                        [ngClass]="{ 'is-invalid': f.submitted && phoneNumber.invalid }" required minlength="10"
                        maxlength="10" />
                      <div *ngIf="f.submitted && phoneNumber.invalid" class="invalid-feedback">
                        <div *ngIf="phoneNumber.errors.required">Phone Number is required</div>
                        <div *ngIf="(phoneNumber.errors.minlength || phoneNumber.errors.pattern)">Phone Number must be
                          valid</div>

                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-label">ZIP Code</label>
                      <!-- <input type="text" value="" class="form-field" /> -->
                      <input type="text" class="form-field" name="zipCode" [(ngModel)]="model.zipCode"
                        #zipCode="ngModel" [ngClass]="{ 'is-invalid': f.submitted && zipCode.invalid }" required
                        minlength="5" maxlength="5" />
                      <div *ngIf="f.submitted && zipCode.invalid" class="invalid-feedback">
                        <div *ngIf="zipCode.errors.required">Zip Code is required</div>
                        <div *ngIf="zipCode.errors.minlength">Zip Code must be 5 characters</div>
                        <div *ngIf="zipCode.errors.maxlength">Zip Code must be 5 characters</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group">
                      <label class="form-label">Email</label>
                      <!-- <input type="text" value="" class="form-field" /> -->
                      <input type="text" class="form-field" name="email" [(ngModel)]="model.email" #email="ngModel"
                        [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email />
                      <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                        <div *ngIf="email.errors.required">Email is required</div>
                        <div *ngIf="email.errors.email">Email must be a valid email address</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-label">Specialized Service</label>
                      <select class="form-field" name="Specialized" [(ngModel)]="model.Specialized"
                        #Specialized="ngModel" [ngClass]="{ 'is-invalid': f.submitted && Specialized.invalid }"
                        required>
                        <option [ngValue]="undefined">---Select---</option>
                        <option *ngFor="let item of SpecializationList" [value]="item.Speciality">
                          {{item.Speciality}}
                        </option>
                      </select>
                      <div *ngIf="f.submitted && Specialized.invalid" class="invalid-feedback">
                        <div *ngIf="Specialized.errors.required">Specialization is required</div>
                      </div>
                    </div>
                    <div class="marginTop marginLeft">
                      <p>
                        By submitting this form, you are agreeing to our terms of use.
                      </p>
                    </div>
                    <div class="form-row marginLeft text-center " style="align-items: center;">
                      <button class="btn btnSubmit">Submit Resume</button>
                    </div>

                  </div>
                </div>

              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<div class="notification">
  <div class="alert alert-success" *ngIf="Success">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    <p>{{ successMessage }}</p>
  </div>
  <div class="alert alert-danger" *ngIf="Fail">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    <p>{{ errorMessage }}</p>
  </div>
</div>