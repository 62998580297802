import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/shared/services/server.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  model: any ={};
  BlogList: any;
  nodata:any = false;
    constructor(private serverService: ServerService, private router:Router, private location: Location, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);
     this.FetchBlogList();
  }
  FetchBlogList(){
    this.model.AccountId=2
    
    this.serverService.FetchArticles(this.model)    
    .subscribe(
   (data:any )=>{ 
        //console.log(data);
      
      this.BlogList={};
      // this.BlogList=data.ArticleList;
      const filterValue = "BLOG";
      const result = data.ArticleList.filter(element => {
        return element.ArticleType === filterValue;
      });
            this.BlogList =result;
            if( this.BlogList=="" || this.BlogList.length==0){
                        this.nodata=true;
                      }
                      else{
                        this.nodata=false;
                      }
   } );

  }

  ViewBlog(blog){
    this.serverService.setdata(blog);
   // this.router.navigateByUrl("/blogdetails")
   const EncodeURL = btoa(blog.ArticleId);
      // console.log(EncodeURL);
     this.router.navigate(["/blogdetails"], { queryParams: { q: EncodeURL } });
 }

}
