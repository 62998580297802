import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visitor-management-system',
  templateUrl: './visitor-management-system.component.html',
  styleUrls: ['./visitor-management-system.component.css']
})
export class VisitorManagementSystemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
