import { Component, OnInit, ViewChild, Input, OnDestroy, ElementRef ,  ViewEncapsulation } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { ServerService } from 'src/app/shared/services/server.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-crud-archivejob', 
  templateUrl: './crud-archivejob.component.html',
  styleUrls: ['./crud-archivejob.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
  
})
export class CrudArchivejobComponent implements OnInit { model: any = {};
jobmodel: any = {};
newORupdate: boolean;
Success: any;
Fail: any;
show:boolean = false;
errorMessage: any;
successMessage: any;
@ViewChild('f') formValues;
  JobList: any;
constructor(  private serverService: ServerService, private router: Router, private datePipe: DatePipe) { }

ngOnInit() {
  this.NewOrUpdate();
  this.update();
  this.FetchJobTitleList();
}


NewOrUpdate() {

  this.model = this.serverService.getdata();
  if (this.model == undefined) {
    this.newORupdate = true;
  }
  else {
    this.newORupdate = false;
  }

}
update() {

  this.model = this.serverService.getdata();
  if (this.model == undefined) {
    this.model = {}
  }
  else {
  
    this.model.title = this.model.JobTitle;  
      this.model.startdate = this.datePipe.transform(this.model.StartDate, 'yyyy-MM-dd');
      this.model.enddate = this.datePipe.transform(this.model.EndDate, 'yyyy-MM-dd');
      this.model.jobrole = this.model.JobDuties;
      this.model.jobrequirement = this.model.JobRequirement;
  }
  this.serverService.blogdatatoedit = undefined;

}
onSubmit(f,status) {
  const data = this.model.JobId
  if (data == undefined) {
    if(status == 'Yes'){
    this.jobmodel.JobTitle = this.model.title;  
    this.jobmodel.StartDate =  this.datePipe.transform(this.model.startdate, 'MM/dd/yyyy');
    this.jobmodel.EndDate =  this.datePipe.transform(this.model.enddate, 'MM/dd/yyyy'); 
    this.jobmodel.JobDuties = this.model.jobrole;
    this.jobmodel.JobRequirement = this.model.jobrequirement;
    this.jobmodel.AccountId = 2;
    this.serverService.AddArchiveJob(this.jobmodel).subscribe(
      res => {
        
        if (res['Response'].status == 200) {
          this.Success = true;
          this.successMessage = res['ArchiveJobsData'].ReturnVal;
          this.router.navigate(['/archivejoblist'])
          setTimeout(() => {
            this.Success = false;
            this.successMessage = '';
          }, 3000);
        }
        else if(res['Response'].status == 400 || 500){
        
                    this.Fail = true;
                    this.errorMessage = res['Response'].message;
                    setTimeout(() => {
                      this.Fail = false;
                      this.errorMessage = '';
                    }, 3000);
                  }

      });
    this.formValues.resetForm();
    }
       // this.Comp.FetchBlogList();
  }
  else {
    const obj: any = {};
    obj.JobId = this.model.JobId;
   const d= this.model.startdate;
    obj.StartDate = this.datePipe.transform(this.model.startdate, 'MM/dd/yyyy');
    obj.EndDate = this.datePipe.transform(this.model.enddate, 'MM/dd/yyyy');
    obj.JobTitle = this.model.title;
    obj.JobDuties = this.model.jobrole;
    obj.JobRequirement = this.model.jobrequirement;

    this.serverService.ModifyArchiveJob(obj).subscribe(
      res => {
        if (res['Response'].status == 200) {

          this.Success = true;
          this.successMessage = res['ArchiveJobsData'].ReturnVal;
          setTimeout(() => {
            this.Success = false;
            this.successMessage = '';
          }, 3000);
        }
        else {
          this.Fail = true;
          this.errorMessage = res['ArchiveJobsData'].ReturnVal;
          setTimeout(() => {
            this.Fail = false;
            this.errorMessage = '';
          }, 3000);
        }

      });
    this.formValues.resetForm();
    setTimeout(()=>{
      this.router.navigateByUrl('/archivejoblist')
    },3000)

    }

}

Cancel() {

  this.formValues.resetForm();
  this.router.navigateByUrl('/archivejoblist')
}
CancelArchiveJob() {

    this.formValues.resetForm();

    this.router.navigateByUrl('/archivejoblist')
  }

Delete() {
  // debugger;
   const obj: any = {}
   obj.JobId = this.model.JobId;

   this.serverService.DeleteArchiveJob(obj).subscribe(
     res => {
       console.log(res)
       if (res['Response'].status == 200) {
         this.Success = true;
         this.successMessage = res['ArchiveJobsData'].ReturnVal;
         setTimeout(() => {
           this.Success = false;
           this.successMessage = '';
         }, 3000);
       }
       else {
         this.Fail = true;
         this.errorMessage = 'Error while processing';
         setTimeout(() => {
           this.Fail = false;
           this.errorMessage = '';
         }, 3000);
       }
     }

   );
   this.router.navigateByUrl('/archivejoblist')
  
 }

 FetchJobTitleList() {
  //this.model.AccountId = 2

  this.serverService.FetchJobTitleList().subscribe(
      (data: any) => {
        // console.log(data);
        if (data.Response.status = '200') {
          this.JobList = data.JobtileList;
        }  else {
          this.Fail = true;
          this.errorMessage = "error Occured!!"
          setTimeout(() => {
            this.Fail = false;
            this.errorMessage = '';
          }, 3000);


        }


      });

}
}
