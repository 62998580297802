import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/shared/services/server.service';
import { Router, RouterModule, PreloadAllModules, NavigationEnd } from '@angular/router';

import { Location } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-home1',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class Home1Component implements OnInit {

  TeamList: any;
  LatestNews: any;
  model: any ={};
  imgURL: any;
  ImagePath: any;
  NewsList: any;
  teaminfo : any;
  TeamName: any;
  TeamTitle:any;
  role:any;
  slides= [
    { 
      slideUrl: 'assets/images/slide1.jpg', 
      slideAnimation:"slideFromRightFast",
      // text: "We at Spino offer a wide range of IT and non-IT services, with world-class quality and support",
      //textAnimation : "slideFromLeft",
      buttonAnimation: "slideFormRight"
    },
    { 
      slideUrl: 'assets/images/slide2.png', 
      slideAnimation:"rotate",
      text: "SPINOINC IS A LEADING PROVIDER OF STAFFING, CONSULTING, AND SOLUTIONS IN THE DISCIPLINES OF INFORMATION TECHNOLOGY, FINANCE, PHARMACEUTICAL, AND HEALTHCARE",
      textAnimation : "slideFromRight",
      buttonAnimation: "slideFormRight"
    },
    { 
      slideUrl: 'assets/images/slide3.png', 
      slideAnimation:"slideFromBottom ",
      text: "THE TEAM AT SPINOINC IS HIGHLY SKILLED AND HAS PRIOR WORKING EXPERIENCE IN LEADING ORGANIZATIONS. OUR SERVICES INCLUDE STAFFING, CONSULTING, DEVELOPMENT FOR SMALL AND MEDIUM ENTERPRISES",
      textAnimation : "slideFromRight",
      buttonAnimation: "slideFormRight"
    }
  ]
  constructor(private serverService: ServerService, private router:Router, private location: Location) { }

  ngOnInit(): void {
    this.FetchTeamList();
    this.FetchNewsList();
  	$.getScript("../assets/js/custom.js");
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
  FetchTeamList(){
    this.model.AccountId=2
    this.serverService.FetchOurTeam(this.model).subscribe(
   (data:any )=>{ 
      this.TeamList=data.OurTeamList;
   } );

  }
  FetchNewsList(){
    this.model.AccountId=2
    this.serverService.FetchArticles(this.model)    
    .subscribe(
   (data:any )=>{ 
    this.LatestNews = data[0];
      this.NewsList={};
      // this.BlogList=data.ArticleList;
      const filterValue = "NEWS";
      const result = data.ArticleList.filter(element => {
        return element.ArticleType === filterValue;
      });
      this.NewsList =result;     
   } );

  }
  ViewTeam(user){
    this.serverService.setdata(user);
    this.model= this.serverService.getdata();
    this.TeamName= this.model.EmpFullName;
  this.TeamTitle = this.model.EmpTitle;
  this.role = this.model.RoleResponsiblity;
  this.imgURL=this.model.ImagePath
  }
  ViewNews(news) {
    window.scrollTo(0,0);
    // this.serverService.setdata(news);
    // this.router.navigateByUrl("/newsdetails")
    const EncodeURL = btoa(news.ArticleId);
    // console.log(EncodeURL);
   this.router.navigate(["/newsdetails"], { queryParams: { q: EncodeURL } });
  }

}
