<header class="innner-page" >
    <div class="container">
        <h1><i class="fa fa-angle-double-right" aria-hidden="true"></i>Add Job</h1>
    </div>
</header>
<nav aria-label="breadcrumb" >
    <ol class="breadcrumb no-gutters">
        <li class="breadcrumb-item">
            <a placement="bottom" ngbTooltip="Homepage" routerLink="/dashboard" skipLocationChange>Home
            </a>
        </li>
        <!-- <li class="breadcrumb-item">
            <a placement="bottom" ngbTooltip="News" routerLink="/addtitle" skipLocationChange>Job title List
            </a>
        </li> -->
        <li class="breadcrumb-item active" aria-current="page">Add Title
        </li>
    </ol>
</nav>
<div class="container" role="main">
    <div class="alert alert-success" *ngIf="Success">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <p>{{ successMessage }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="Fail">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <p>{{ errorMessage }}</p>
    </div>
    <div class="col-md-12 admpad">
        <form #f="ngForm" name="form" aria-label="form" class="pure-form pure-form-stacked formborder"
            (ngSubmit)="f.form.valid && onSubmit(f)" novalidate>
            <div class="col-md-12">
                <div class="form-group">
                    <span class="h6">Title :</span>
                    <input type="text" class="form-control" name="title" [(ngModel)]="model.title" #title="ngModel"
                        placeholder="Enter Your Title" [ngClass]="{ 'is-invalid': f.submitted && title.invalid }"
                        required />
                    <div *ngIf="(title.touched ||f.submitted) && title.invalid" class="invalid-feedback">
                        <div *ngIf="title.errors.required">Title is required</div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 padbtm">
                <button type="submit" class="col-md-3 btn btn-primary">Submit</button>
                <button type="reset" class="col-md-3 btn btn-cancel" (click)="CancelJobTitle()">Cancel</button>
            </div>
        </form>
    </div>
</div>


<br>

<div class="container" role="main">
    <div class="row ">
        <h5>Job Title List:</h5>
        <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="nodata==false">
            <!-- <div class="row">
      <div class="col-lg-8 mx-auto table-responsive "> -->
            <div>
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">S.No</th>

                            <th class="text-left thpad">Title</th>

                            <th>Delete</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let j of JobList; let i = index">
                            <td class="text-center">{{i+1}}</td>

                            <td class="text-left">

                                <button type="button" class="btnn btn btn-link TitleText" (click)="updateJobTitle(j)"
                                    placement="bottom" aria-expanded="false" aria-label="blog"
                                    ngbTooltip="Modify Blog">{{j.title | titlecase}}
                                    <!-- {{user.Title | titlecase  | slice:0:30}} -->
                                </button>
                            </td>


                            <td>
                                <div class="align-div-center">
                                    <button type="button" class="btn btn-outline-danger" data-toggle="modal"
                                        data-target="#myModal" aria-expanded="false" aria-label="deltitle"
                                        (click)="DeleteJobTitle(j, '')" placement="bottom" ngbTooltip="Delete Title">
                                        <span class="fa fa-times-circle-o"></span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-12 " *ngIf="nodata==true">
            <div class="Nodata"> No Titles Found</div>
        </div>
    </div>
</div>
<!-- data-toggle="modal" data-target="#myModal" -->
<div id="myModal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-times"></i>
                </div>
                <h4 class="modal-title">Are you sure?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <p>Do you really want to delete this record? This process cannot be undone.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal"
                    (click)="DeleteJobTitle(model,'Yes')">Delete</button>
            </div>
        </div>
    </div>
</div>