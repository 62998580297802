import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import{ImagevalidationService} from 'src/app/shared/services/imagevalidation.service';
import{BlogListComponent} from 'src/app/components/admin/blog-list/blog-list.component';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { ServerService } from 'src/app/shared/services/server.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImageCroppedEvent, ImageTransform } from '../image-cropper/interfaces';

@Component({ 
  providers: [BlogListComponent],
  selector: 'app-crud-blog',
  templateUrl: './crud-blog.component.html',
  styleUrls: ['./crud-blog.component.css']
})
export class CrudBlogComponent implements OnInit {
  model: any = {};
  newORupdate: boolean;
  BlogList: any;
  Blogmodel: any = {};
  title: string;
  Success: any;
  Fail: any;
  errorMessage: any;
  successMessage: any;
  description: string;
  blob;
  filestream: any;
  ImgValidate: any;
  imageChangedEvent: any = '';
  selectedFile: File = null;
  // Image Variables
  public imagePath;
  imgURL: any;
  croppedImage: any = '';
  scale = 1;
  transform: ImageTransform = {};
  showCropper = false;
  canvasRotation = 0;
  rotation = 0;
  @ViewChild('f') formValues;
  private unsubscribe$ = new Subject<void>();
  constructor(private imagevalidation: ImagevalidationService,
    private serverService: ServerService, private router: Router, private Comp: BlogListComponent) { }

  ngOnInit() {
    this.NewOrUpdate();
    this.updateblog();

  }

  onSubmit(f: NgForm) {
    const data = this.model.ArticleId
    if (data == undefined) {
      const fileData: any = {};
      this.Blogmodel.ArticleTitle = this.model.title;

      this.Blogmodel.ArticleDescription = this.model.description;
       fileData.fileStream = this.onimageload();
      //this.Blogmodel.fileStream='';
      if(this.selectedFile['name']==null){
        return;
      }
      this.Blogmodel.fileName = this.selectedFile['name'];
      this.Blogmodel.AccountId = 2;
      this.Blogmodel.MemberId = 1;
      this.Blogmodel.ArticleType = 'BLOG';

      this.serverService.ArticlePosting(this.Blogmodel, fileData.fileStream).subscribe(
        res => {
          if (res['Response'].status == 200) {

            this.Success = true;
            this.successMessage = res['ArticleData'].ReturnVal;
            setTimeout(() => {
              this.Success = false;
              this.successMessage = '';
            }, 3000);
          }
          else {
            this.Fail = true;
            this.errorMessage = res['ArticleData'].ReturnVal;
            setTimeout(() => {
              this.Fail = false;
              this.errorMessage = '';
            }, 3000);
          }


        });
      this.formValues.resetForm();

      this.imgURL = '';
      this.croppedImage = '';
      this.blob = '';
      this.Comp.FetchBlogList();
    }
    else {
      const obj: any = {};
      obj.ArticleId = this.model.ArticleId;
      obj.MemberId = 1;
      obj.ArticleTitle = this.model.title;
      obj.ArticleDescription = this.model.description;
      const fileData: any = {};
      // this.Blogmodel.fileStream='';
      if (this.selectedFile != null) {
        obj.fileName=this.selectedFile['name'];
       fileData.fileStream = this.onimageload();
      } else {
        obj.fileName='';
        fileData.fileStream = '';
      }
      debugger
      this.serverService.ModifyArticle(obj, fileData.fileStream).subscribe(
        res => {
          debugger
          if (res['Response'].status == 200) {

            this.Success = true;
            this.successMessage = res['ArticleData'].ReturnVal;
            setTimeout(() => {
              this.Success = false;
              this.successMessage = '';
            }, 3000);
          }
          else {
            this.Fail = true;
            this.errorMessage = res['ArticleData'].ReturnVal;
            setTimeout(() => {
              this.Fail = false;
              this.errorMessage = '';
            }, 3000);
          }

        });
      this.formValues.resetForm();

      this.imgURL = '';
      // debugger;
      this.Comp.FetchBlogList();
     
      setTimeout(()=>{
        this.router.navigateByUrl('/bloglist')
      },3000)
      
    }

  }
  updateblog() {

    this.model = this.serverService.getdata();
    if (this.model == undefined) {
      this.model = {}
    }
    else {
      this.model.title = this.model.ArticleTitle;
      this.model.description = this.model.ArticleDescription;
      this.model.ImagePath= this.model.ImagePath

    }
    this.serverService.blogdatatoedit = undefined;

  }

  FetchBlogList() {
    this.model.AccountId = 2

    this.serverService.FetchArticles(this.model).pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: any) => {
          // console.log(data);
          this.BlogList = data.ArticleList;

        });

  }

  NewOrUpdate() {

    this.model = this.serverService.getdata();
    if (this.model == undefined) {
      this.newORupdate = true;
    }
    else {
      this.newORupdate = false;
    }

  }
  CancelBlog() {

    this.formValues.resetForm();

    this.imgURL = '';
    this.Comp.FetchBlogList();

    this.router.navigateByUrl('/bloglist')
  }
  DeleteBlog() {

    const obj: any = {}
    obj.ArticleId = this.model.ArticleId;

    this.serverService.DeleteArticle(obj).subscribe(
      res => {
        console.log(res)
        if (res['Response'].status == 200) {
          this.Success = true;
          this.successMessage = res['ArticleData'].ReturnVal;
          setTimeout(() => {
            this.Success = false;
            this.successMessage = '';
          }, 3000);
        }
        else {
          this.Fail = true;
          this.errorMessage = 'Error while processing';
          setTimeout(() => {
            this.Fail = false;
            this.errorMessage = '';
          }, 3000);
        }
      }


    );
    this.FetchBlogList();
    setTimeout(()=>{
      this.router.navigateByUrl('/bloglist')
    },3000)
   
  }
  onimageload() {
    try{
      // this.updateResult = {};
      const fileData: any = {};
    
      fileData.data = this.selectedFile['name'];
      this.blob.name =  fileData.data
      this.blob.webkitRelativePath = '';
      fileData.fileStream = this.blob;
     
return fileData.fileStream
    }
    catch(err){

    }

  }

  // method for select file
  fileChangeEvent(event: any): void {
    try {

      // image validation will be get done here
      this.ImgValidate = this.imagevalidation.ValidateImage(event);
      if (this.ImgValidate === true) {

        this.imageChangedEvent = event;
        // tslint:disable-next-line
        this.selectedFile = event.target.files[0];
        var reader = new FileReader();
        this.imagePath = event.target.fiiles;
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => { 
          
                  this.imgURL = reader.result; 
                  
                   fetch(this.imgURL)
                   .then(res => res.blob())
                  .then(blob => {
                   const fd = new FormData();
                     fd.append('image', blob, 'filename');
                     this.blob = blob;
                   });
        // reader.onload = (event: ProgressEvent) => {
        //   this.imgURL = (<FileReader>event.target).result;

        // };
                  };
       

      } else {
        this.Fail = true;
        this.errorMessage = this.ImgValidate;
        setTimeout(() => {
          this.Fail = false;
          this.errorMessage = '';
        }, 3000);
      }
    } catch (error) {
      throw error;
    }

  }


  imageCropped(event: ImageCroppedEvent) {
    try {

      this.croppedImage = event.base64;
      const url = this.croppedImage;
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const fd = new FormData();
          fd.append('image', blob, 'filename');
          this.blob = blob;
        });
    } catch (error) {
      throw error;
    }
  }
  imageLoaded() {
    try {
      this.showCropper = true;
    } catch (error) { throw error; }
  }
  rotateLeft() {
    try {
      this.canvasRotation--;
      this.flipAfterRotate();
    } catch (error) {
      throw error;
    }
  }
  rotateRight() {
    try {
      this.canvasRotation++;
      this.flipAfterRotate();
    } catch (error) {
      throw error;
    }
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  flipHorizontal() {
    try {
      this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
      };
    } catch (error) {
      throw error;
    }
  }
  flipVertical() {
    try {
      this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
      };
    } catch (error) {
      throw error;
    }
  }
  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
  cropperReady() {
  }
  loadImageFailed() {
  }
  onUpload() {
    this.model.ImagePath = this.croppedImage;
    document.getElementById('dismissImgModal').click();
    this.imgURL=''
  }
  cancelImg() {
    try {
      this.croppedImage = '';
      document.getElementById('dismissImgModal').click();
    } catch (error) {
      throw error;

    }
  }
  cancelImage() {
    try {
      this.imgURL = '';
    } catch (error) {
      throw error;

    }
  }

  zoomOut(e) {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn(e) {

    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }


}
