import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms'; //imports
import { ServerService } from 'src/app/shared/services/server.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  @ViewChild('f') formValues;
  model: any ={};
  name : string;
  phone : string;
  email : string;
  comment : string;
  success: boolean =false;
  Fail: boolean = false;
  errorMessage:any;
  successMessage: any;
    constructor(
      private serverService: ServerService, private location: Location) { }

  ngOnInit(): void {
  }
  onSubmit(f) {
  
    this.model.RecepientName=this.model.name;
    this.model.RecepientEmail=this.model.email;
    this.model.Comment=this.model.comment;
    this.model.PhoneNumber=this.model.phone;
    // alert(JSON.stringify(this.model));
     
      this.serverService.SendEmail(this.model)
     .subscribe(data => {
      
       if(data["Response"].status){
         this.success=true;
       this.successMessage = data["Result"];
       this.formValues.resetForm();
      //  console.log(data["ReturnVal"].message)
       setTimeout(() => {
        this.success = false;
        this.successMessage = '';
                }, 3000);
     }
     else {
this.Fail = true;
this.errorMessage = data["Result"];
this.formValues.resetForm();
setTimeout(() => {
this.Fail = false;
this.errorMessage = '';
        }, 3000);

     }
     
     })   
    }
    

}
