import { Component, HostListener, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute} from '@angular/router';
import { ServerService } from 'src/app/shared/services/server.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'SpinoInc';
  adminname: any = localStorage.getItem('adminID');
  constructor(
    public router: Router,
    private http: HttpClient,
    private serverService: ServerService
  ) { }
 
  ngOnInit() {
    this.refreshAdmin();
  }

  refreshAdmin(){
    if(this.adminname == '2'){
      const EncodeURL = btoa('Admin Dashboard');
      // console.log(EncodeURL);
      this.router.navigate(["/dashboard"], { queryParams: { q: EncodeURL } });
    }
    
    // else{
    //   // this.WelcomeModal.nativeElement.click();
    //   this.router.navigateByUrl("/home")
    // }
  }
}
