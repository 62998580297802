import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms'; //imports
import { ServerService } from 'src/app/shared/services/server.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-archivedetails',
  templateUrl: './archivedetails.component.html',
  styleUrls: ['./archivedetails.component.css']
})
export class ArchivedetailsComponent implements OnInit {
  model: any ={};
  ArchievJobList: any;
  imgURL: any;
  JobTitle: any;
  JobDuties: any;
  StartDate: any;
  JobRequirement: any;
  EndDate: any;
  nodata= false;
  JobId: any;
  blogDescription:any;
  @ViewChild('div',  {static: true}) div: ElementRef;
  @ViewChild('div2',  {static: true}) divr: ElementRef;
private unsubscribe$ = new Subject<void>();
subs:Subscription;
QueryParamId: string;
jobdetails: any;
  constructor(private serverService: ServerService, private renderer: Renderer2, private router:Router, private route: ActivatedRoute) { }

  ngOnInit() {
    //this.viewArchieve();
    this.route.queryParamMap.subscribe((URLparams: any) => {
      const EncodeURL = URLparams.params.q;
      this.QueryParamId = atob(EncodeURL);
      console.log(this.QueryParamId);
    }); 
    this.viewArchiveJobBYURL(this.QueryParamId);
  }

  
  viewArchiveJobBYURL(Id){
    this.model.AccountId=2
    this.serverService.FetchArchiveJobs(this.model)
    .subscribe( (data:any )=>{ 
      
      this.jobdetails = data.ArchiveJobsList.filter(element => {
         console.log(element);
         return element.JobId === parseInt(Id) ;
       });
    
       this.JobTitle = this.jobdetails[0].JobTitle;
         this.StartDate = this.jobdetails[0].StartDate;
         this.EndDate = this.jobdetails[0].EndDate;
         const k = this.jobdetails[0].JobDuties;
         const j = this.jobdetails[0].JobRequirement;
          const d = k.split('@NEL@');
          const r= j.split('@NEL@');
         //console.log(o);
         d.forEach((element, index) => {
           const p: HTMLParagraphElement = this.renderer.createElement('p');
           p.innerHTML = d[index];
           this.renderer.appendChild(this.div.nativeElement, p)
     
         }),
         r.forEach((element, index) => {
           const p: HTMLParagraphElement = this.renderer.createElement('p');
           p.innerHTML = r[index];
           this.renderer.appendChild(this.divr.nativeElement, p)
     
         });
     
    });
  }

  
  viewArchieve(){  
      
    this.model= this.serverService.getdata();
    
    this.JobId=this.model.JobId;
    this.JobTitle = this.model.JobTitle;
   
    this.StartDate = this.model.StartDate;
    this.EndDate = this.model.EndDate;
    const j =this.model.JobRequirement
    const k  = this.model.JobDuties;
   
    const d = k.split('@NEL@');
    const r= j.split('@NEL@');
   //console.log(o);
   d.forEach((element, index) => {
    
     const p: HTMLParagraphElement = this.renderer.createElement('p');
     p.innerHTML = d[index];
    //  console.log(this.div);
     this.renderer.appendChild(this.div.nativeElement, p)

   }),
   r.forEach((element, index) => {
     const p: HTMLParagraphElement = this.renderer.createElement('p');
     p.innerHTML = r[index];
     this.renderer.appendChild(this.divr.nativeElement, p)

   })    
  }
  Applyjob(){
    window.scrollTo(0,0);
    this.router.navigateByUrl("/Resumeupload")
  }
  Canceljob(){
    window.scrollTo(0,0);
    this.router.navigateByUrl("/archivejobs")
  }

}
