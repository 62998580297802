<div role="main">
  <!-- <animate-slider 
  [slide]="slides" title="slide" aria-hidden="true"
   [captionText]="true" [height]="35"
   [autoPlay] = "true"
    [speed] = "4500"
    [textColor]="'#FCFCFC'"
    >
  </animate-slider> -->
  <animate-slider 
  [slide]="slides" title="slide" aria-hidden="true"
    [height]="35"
   [autoPlay] = "true"
   [speed] = "4500"
    >
  </animate-slider>

  <section class="section-padding-ash">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="section-title text-center">
            <h1>What we Provide to our Clients</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div class="services-cont">
            <div class="icon-cont"><i class="icofont icofont-paint"></i></div>
            <div class="text-cont">
              <span class="h6heading">Web Design</span>
              <p class="just"> We’re a top-rated NJ company focused on creative and results-driven solutions.
                we support to increase profits, provide IT Solutions, sell products or promote your brand.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div class="services-cont">
            <div class="icon-cont"><i class="icofont icofont-code-alt"></i></div>
            <div class="text-cont">
              <span class="h6heading">Web Development</span>
              <p class="just">Our services are designed to take your business to the next level, increase revenue,
                reducing the gap between you and the heights of success you wish to achieve. </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div class="services-cont">
            <div class="icon-cont"><i class="icofont icofont-dashboard-web"></i></div>
            <div class="text-cont">
              <span class="h6heading">Online Marketing</span>
              <p class="just">We're a performance marketing agency that solves the most challenging business problems in
                PPC, SEO, lead generation, and conversion optimization.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div class="services-cont">
            <div class="icon-cont"><i class="icofont icofont-brand-android-robot"></i></div>
            <div class="text-cont">
              <span class="h6heading">Android Development</span>
              <p>We are an Android development firm that designs, develops, and deploys custom mobile solutions for
                organizations that want to make an impact through technology.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div class="services-cont">
            <div class="icon-cont"><i class="icofont icofont-iphone"></i></div>
            <div class="text-cont">
              <span class="h6heading">iPhone Development</span>
              <p class="just">We provide advanced and best features in all areas of iOS development including API
                integrations, online payment processing, video & voice streaming.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div class="services-cont">
            <div class="icon-cont"><i class="icofont icofont-video-clapper"></i></div>
            <div class="text-cont">
              <span class="h6heading">Online Training</span>
              <p class="just"> Advance your skills in delivering effective and engaging synchronous (live and online)
                training.
                Provide online-training and in-person training sessions to the best level.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-padding-about">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-lg-offset-right-6">
          <div class="text-left">
            <h1>Why Choose us</h1>
            <p class="just">Spinoinc is a leading provider of staffing, consulting, and solutions in the disciplines of
              information technology, finance, pharmaceutical, and healthcare. Spinoinc delivers its services to
              government entities and businesses in virtually all industries throughout the United States & India. We
              deliver business solutions for the unique requirements of each client. Spinoinc was started to assist
              clients in achieving mission-critical business objectives with state-of-the-art technologies and maximize
              their ability to deliver value to their customers, business value chain partners.
            </p>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="counter-cont">
                <div class="scrollcount"><span class="numscroller" data-min="1" data-max="50" data-delay="5"
                    data-increment="10">50</span>+</div>
                <span class="h6heading">Clients</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="counter-cont">
                <div class="scrollcount"><span class="numscroller" data-min="1" data-max="50" data-delay="5"
                    data-increment="10">50</span>+</div>
                <span class="h6heading">Projects</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="counter-cont">
                <div class="scrollcount"> <span class="numscroller" data-min="1" data-max="50" data-delay="5"
                    data-increment="10">50</span>+</div>
                <span class="h6heading">Products</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 d-none d-lg-block">
         <img src="assets/images/aboutnew.jpg" alt="about">
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-lg-6 col-lg-offset-right-6">
         
        </div>
      </div> -->
    </div>
  </section>
  <section class="section-padding-ash">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="section-title text-center">
            <h2>Our Recent News</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12" *ngFor="let news of NewsList| slice:0:3">
          <div class="blog-post-cont"> <a class="cursor" (click)="ViewNews(news)"><img [src]="news.ImagePath"
                class="newspad" alt="News"></a>
            <div class="text-cont">
              <span class="h6heading"><a>SpinoInc</a> | <a>{{news.ArticleDate}}</a></span>
              <span class="newtitle h5heading"><a (click)="ViewNews(news)">{{news.ArticleTitle}}..</a></span>
              <p class="Description just">{{news.ArticleDescription}}..</p>
              <a class="readdmore" (click)="ViewNews(news)">Read More <i
                  class="icofont icofont-long-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>