import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import{ImagevalidationService} from 'src/app/shared/services/imagevalidation.service';
import{OurteamListComponent} from 'src/app/components/admin/ourteam-list/ourteam-list.component';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { ServerService } from 'src/app/shared/services/server.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImageCroppedEvent, ImageTransform } from '../image-cropper/interfaces';

@Component({
  providers: [OurteamListComponent],
  selector: 'app-crud-our-team',
  templateUrl: './crud-our-team.component.html',
  styleUrls: ['./crud-our-team.component.css']
})


export class CrudOurTeamComponent implements OnInit {
  model: any ={};
  newORupdate:boolean;
  Teammodel: any ={};
  TeamList: any;
  Success: any;
  Fail: any;
  errorMessage: any;
  successMessage: any;
  blob;
  filestream: any;
  ImgValidate: any;
  imageChangedEvent: any = '';
  selectedFile: File = null;
  fname : string;
  lastname : string;
  etitle : string;
  erole : string;
  @ViewChild('f') formValues;
// Image Variables
public imagePath;
imgURL: any;
croppedImage: any = '';
scale = 1;
showCropper = false;
canvasRotation = 0;
transform: ImageTransform = {};
private unsubscribe$ = new Subject<void>();
rotation = 0;
//transform: ImageTransform = {};
  constructor(private imagevalidation: ImagevalidationService,
    public router: Router,
    private http: HttpClient,
    private serverService: ServerService, private Comp: OurteamListComponent) { }

  ngOnInit() {
    this.NewOrUpdate();
    this.updateteam(); 
  }
  
  onimageload() {
    // this.updateResult = {};
    const fileData: any = {};
       
    fileData.data = this.selectedFile['name'];
    this.blob.name =  fileData.data
    this.blob.webkitRelativePath = '';
    fileData.fileStream = this.blob;
   
return fileData.fileStream
}

onSubmit(f:NgForm) {
  const data = this.model.EmployeeId
if(data==undefined){
  const fileData:any ={};
 this.Teammodel.EmpFirstName=this.model.fname;
 this.Teammodel.EmpLastName=this.model.lastname;
 this.Teammodel.EmpTitle=this.model.etitle;
 this.Teammodel.RoleResponsiblity=this.model.erole;
 fileData.fileStream =this.onimageload();
 //this.Teammodel.fileStream='';
this.Teammodel.fileName= this.selectedFile['name'];
this.Teammodel.AccountId=2;
    console.log(this.Teammodel);
 this.serverService.AddOurTeam(this.Teammodel, fileData.fileStream).subscribe(
   res=>{
    console.log(res);
     if(res['Response'].status==200)
     { 
      
       this.Success=true;
       this.successMessage=res['OurTeamData'].ReturnVal;
       setTimeout(() => {
         this.Success = false;
         this.successMessage = '';
                 }, 10000);
     }
     else{
       this.Fail=true;
       this.errorMessage=res['OurTeamData'].ReturnVal;
       setTimeout(() => {
         this.Fail = false;
         this.errorMessage = '';
                 }, 10000);
     }
   })
this.formValues.resetForm();
 this.imgURL='';
 this.croppedImage='';
 this.blob='';
 //this.Comp.FetchTeamList();
  }
  else{
    const obj:any = {};
     obj.EmployeeId= this.model.EmployeeId;
     obj.EmpFullName= this.model.fname;
    obj.EmpTitle= this.model.etitle;
    obj.RoleResponsiblity=this.model.erole;
    debugger
    const fileData: any = {};
    // this.Blogmodel.fileStream='';
    if (this.selectedFile != null) {
      obj.fileName=this.selectedFile['name'];
     fileData.fileStream = this.onimageload();
    } else {
      obj.fileName='';
      fileData.fileStream = '';
    }
    this.serverService.ModifyOurTeam(obj, fileData.fileStream).subscribe(
       res =>{
         console.log(res);
       if(res['Response'].status==200)
    {
      this.Success=true;
      this.successMessage=res['OurTeamData'].ReturnVal;
      setTimeout(() => {
        this.Success = false;
        this.successMessage = '';
                }, 10000);
    }
    else{
      this.Fail=true;
      this.errorMessage=res['OurTeamData'].ReturnVal;
      setTimeout(() => {
        this.Fail = false;
        this.errorMessage = '';
                }, 10000);
    }

    });
    this.formValues.resetForm();
  
  this.imgURL='';
  //this.Comp.FetchTeamList();
  setTimeout(()=>{this.router.navigateByUrl('/ourteamlist')},10000);
 
   
    
  }
}

updateteam(){
  this.model= this.serverService.getteam();
  // console.log(this.model);
    if(this.model==undefined){
    this.model={}
      }
  else{
  this.model.fname = this.model.EmpFullName;
  this.model.etitle = this.model.EmpTitle;
  this.model.erole = this.model.RoleResponsiblity;
  this.model.ImagePath=this.model.ImagePath
    
  }
  this.serverService.teamdataedit = undefined;
}


FetchTeamList(){
  this.model.AccountId=2
  
  this.serverService.FetchOurTeam(this.model)
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe(
 (data:any )=>{ 
    //console.log(data);
    this.TeamList=data.OurTeamList;
   
 } );

}


 NewOrUpdate(){

  this.model= this.serverService.getteam();
  if(this.model==undefined){
          this.newORupdate=true;
        }
        else{
          this.newORupdate=false;
        }
  
      }
      CancelTeam(){
       
        this.formValues.resetForm();
        
        this.imgURL='';
        // this.Comp.FetchBlogList();
       
          this.router.navigateByUrl('/ourteamlist')
      }
      DeleteTeam(){
  
       const obj:any ={}
         obj.ArticleId=this.model.ArticleId;
        
         this.serverService.DeleteArticle( obj).subscribe(
           res=>{
             console.log(res)
              if(res['Response'].status ==200){ 
               this.Success =true;
              this.successMessage= res['ArticleData'].ReturnVal;
              setTimeout(() => {
               this.Success = false;
               this.successMessage = '';
                       }, 10000);
              }
              else{
                this.Fail=true;
                this.errorMessage= 'Error while processing';
                setTimeout(() => {
                 this.Fail = false;
                 this.errorMessage = '';
                         }, 10000);
              }
             }
      
           
         );
         this.FetchTeamList();
         
         setTimeout(()=>{
         this.router.navigateByUrl('/ourteamlist')

         },10000)
       }
     
  // method for select file
  fileChangeEvent(event: any): void {
    try {

      // image validation will be get done here
      this.ImgValidate = this.imagevalidation.ValidateImage(event);
      if (this.ImgValidate === true) {

        this.imageChangedEvent = event;
        // tslint:disable-next-line
        this.selectedFile = event.target.files[0];
        var reader = new FileReader();
        this.imagePath = event.target.fiiles;
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => { 
          
                  this.imgURL = reader.result; 
                  
                   fetch(this.imgURL)
                   .then(res => res.blob())
                  .then(blob => {
                   const fd = new FormData();
                     fd.append('image', blob, 'filename');
                     this.blob = blob;
                   });
        // reader.onload = (event: ProgressEvent) => {
        //   this.imgURL = (<FileReader>event.target).result;

        // };
                  };
       

      } else {
        this.Fail = true;
        this.errorMessage = this.ImgValidate;
        setTimeout(() => {
          this.Fail = false;
          this.errorMessage = '';
        }, 10000);
      }
    } catch (error) {
      throw error;
    }

  }



  imageCropped(event: ImageCroppedEvent) {
    try {

      this.croppedImage = event.base64;
      const url = this.croppedImage;
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const fd = new FormData();
          fd.append('image', blob, 'filename');
          this.blob = blob;
        });
    } catch (error) {
      throw error;
    }
  }
  imageLoaded() {
    try {
      this.showCropper = true;
    } catch (error) { throw error; }
  }
  rotateLeft() {
    try {
      this.canvasRotation--;
      this.flipAfterRotate();
    } catch (error) {
      throw error;
    }
  }
  rotateRight() {
    try {
      this.canvasRotation++;
      this.flipAfterRotate();
    } catch (error) {
      throw error;
    }
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  flipHorizontal() {
    try {
      this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
      };
    } catch (error) {
      throw error;
    }
  }
  flipVertical() {
    try {
      this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
      };
    } catch (error) {
      throw error;
    }
  }
  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
  cropperReady() {
  }
  loadImageFailed() {
  }
  onUpload() {
    this.model.ImagePath = this.croppedImage;
    document.getElementById('dismissImgModal').click();
    this.imgURL='';
  }
  cancelImg() {
    try {
      document.getElementById('dismissImgModal').click();
      this.croppedImage = '';
    } catch (error) {
      throw error;

    }
  }
  cancelImage() {
    try {
      this.imgURL = '';
    } catch (error) {
      throw error;

    }
  }

  zoomOut(e) {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn(e) {

    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

