<div role="main">
<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/Details_Blog.jpg" alt="First slide">
    </div>
  </div>
</div>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb no-gutters">
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
      </a>
    </li>
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="News" routerLink="/blog" >Blog List
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Blog Details
    </li>
  </ol>
</nav>
<section class="section-padding-ash">
  <div class="container">
    <h1 aria-label="heading"></h1>
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="blog-post-cont"> <img [src]="imgURL" alt="No Image" height="400" width="1000" alt="item 1">
              <div class="text-cont">
                <h2><a>SpinoInc</a> | <a> {{model.ArticleDate}}</a></h2>
                <h3 class="just"><a >{{blogTitle}}</a></h3>
                <p class="just">{{blogDescription}}</p>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf= "nodata == false">
      	<div class="sidebar">
            <div class="sidebar-item">
            	<h3>Recent Blogs</h3>
                <hr/>
                <ul *ngFor="let blog of BlogList| slice:0:5">
                  <li><a class="latest-blog-single-img mobileimg"><a class="cursor" (click)="OpenBlogToLeftSide(blog)"><img [src]="blog.ImagePath"  alt="SpinoInc Image"></a></a></li>
                	<li (click) ="OpenBlogToLeftSide(blog)"><a class="cursor"><p class="leftmenu">{{blog.ArticleTitle| slice:0:50}}..</p></a></li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>