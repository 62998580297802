<div role="main">
<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
	<div class="carousel-inner">
	  <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/adminlogin.jpg" alt="First slide">
	  </div>
	</div>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Login
      </li>
    </ol>
  </nav>
  <body>
	  <!-- Main Content -->
	  <div class="container-fluid">
		  <h1 aria-label="hedaing"></h1>
		  <div class="row main-content bg-success text-center">
			  <div class="col-md-4 text-center company__info">
		  <a  class="navbar-brand page-scroll logo-color"><img src="assets/images/login.jpg" alt="logo"
			width="180" height="100"></a>
				  <!-- <h4 class="company_title">Your Company Logo</h4> -->
			  </div>
			  <div class="col-md-8 col-xs-12 col-sm-12 login_form ">
				  <div class="container-fluid">
					  <div class="row">
						  <h2>Log In</h2>
					  </div> 
					  <div class="invalid" *ngIf= "Fail"> Invalid/Wrong Credentials</div>
						<div class="valid" *ngIf= "success"> Login Success Mr/Mrs <b> <i> {{memberName}}</i></b> </div>
					  <div class="row">
						  <form  class="form-group" #f="ngForm" (ngSubmit)="onLogin(f)">
							  
							  <div class="col-md-12 col-xs-6 col-sm-6 mobilpad">
								  <input type="text"  name="EmailAddress" [(ngModel)]='model.EmailAddress'
								   class="form__input" #EmailAddress="ngModel" placeholder="Enter your Email"  
								   [ngClass]="{ 'is-invalid': f.submitted && EmailAddress.invalid }"  required >
								   <div *ngIf="(EmailAddress.touched ||f.submitted) && EmailAddress.invalid" class="invalid-feedback">
									  <div *ngIf="EmailAddress.errors.required">EmailID is required</div>
								  </div>
							  </div>
							  <div class="col-md-12 col-xs-6 col-sm-6 mobilpad">
								  <!-- <span class="fa fa-lock"></span> -->
								  <input type="password" name="Password" id="Password" class="form__input" placeholder="Password"
								   [(ngModel)]='model.Password' #Password="ngModel" [ngClass]="{ 'is-invalid': f.submitted && Password.invalid }" required>
								   <div *ngIf="(Password.touched ||f.submitted) && Password.invalid" class="invalid-feedback">
									  <div *ngIf="Password.errors.required">Password is required</div>
								  </div>
							  </div>
							  
							<div class="recaptcha g-recaptcha">
								<re-captcha (click)="resolved($event)" style="padding:40px;"  siteKey="6LcBDKsZAAAAANDXanzrkauNhKI9fvjVvUHKvIyI"
								[(ngModel)]='model.captch' name="captch" required> </re-captcha>
							</div>
							  <div class="row col-md-12">
								  <!-- <button type="submit" [disabled]="!f.form.valid" class="btn">Submit</button> -->
								  <input type="submit" value="Submit" [disabled]="!f.form.valid" class="btnlogin btn-block">
							  </div>
						  </form>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
	  <!-- Footer -->
  
  </body>
</div>