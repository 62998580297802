<div role="main">


<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid " src="assets/images/services.jpg" alt="First slide">
    </div>
  </div>
</div>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Services
    </li>
  </ol>
</nav>
<section class="section-padding-ash">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h1>What we Provide to our Clients</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-paint"></i></div>
          <div class="text-cont">
            <span class="h6heading">Web Design</span>
            <p class="just"> We’re a top-rated NJ company focused on creative and results-driven solutions. 
              we support to increase profits, provide IT Solutions, sell products or promote your brand.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-code-alt"></i></div>
          <div class="text-cont">
            <span class="h6heading">Web Development</span>
            <p class="just">Our services are designed to take your business to the next level, increase revenue,
              reducing the gap between you and the heights of success you wish to achieve. </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-dashboard-web"></i></div>
          <div class="text-cont">
            <span class="h6heading">Online Marketing</span>
            <p class="just">We're a performance marketing agency that solves the most challenging business problems in PPC, SEO, lead generation, and conversion optimization.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-brand-android-robot"></i></div>
          <div class="text-cont">
            <span class="h6heading">Android Development</span>
            <p>We are an Android development firm that designs, develops, and deploys custom mobile solutions for organizations that want to make an impact through technology.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-iphone"></i></div>
          <div class="text-cont">
            <span class="h6heading">iPhone Development</span>
            <p class="just">We provide advanced and best features in all areas of iOS development including API integrations, online payment processing, video & voice streaming.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-video-clapper"></i></div>
          <div class="text-cont">
            <span class="h6heading">Online Training</span>
            <p class="just"> Advance your skills in delivering effective and engaging synchronous (live and online) training.
               Provide online-training and in-person training sessions to the best level.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="subscribe">
  <div class="container">
    <div class="row justify-content-center">
    	<div class="col-lg-8">
        	<div class="subscribe-cont">
                <h2 class="txtclor">Subscribe our Newsletter</h2>
                <div class="subscribe-form-cont">
                </div>
            </div>
        </div>
    </div>
  </div>
</section> 
</div>