<div role="main">

<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/archivejob.jpg" alt="First slide">
      </div>
    </div>
  </div>
  <div >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Archive Jobs
        </li>
      </ol>
    </nav>
  </div>
  <div class="container jobSeekersWizard">
    <div class="row">
      <div class="col-md-12 col-xs-12 jobSeekers">
        <div class="col-md-12 col-sm-12 col-xs-12 Seekers">
          <h1 aria-label="heading"></h1>
          <!-- <h1>Job Seekers</h1> -->
            <!-- Spinner start -->
        <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="default"
        color="#fd6121"
        type="line-scale-pulse-out-rapid"
        [fullScreen] = "false" > </ngx-spinner>
         <!-- Spinner end -->
          <div class="tab-content">
            <div class="tab-pane active" id="home-v">
              <table class="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Job Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody class="animation_FadeIn">
                  <tr  *ngFor="let user of archivejobList; let i = index">
                    <td>{{i+1}}</td>
                    <td>
                     {{user.JobTitle}}
                    </td>
                    <td>
                        <span>{{user.StartDate| date: 'MM/dd/yyyy'}}</span>
                    </td>
                    <td>
                      <span>{{user.EndDate| date: 'MM/dd/yyyy'}}</span>
                  </td>
                    <td>
                      <button class="btn btn-primary"  (click)="ViewArchivejob(user)">View</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>