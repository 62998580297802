import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
declare var $:any;

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  constructor(public router: Router, private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  logout() {
   this.authService.logout();
    // remove user from local storage to log user ou
   
  }

  navSts:any=true;
  redirect(){
    if(this.navSts==true){
      $('#navbarNavDropdown').removeClass('show');
    }
   this.navSts=!this.navSts;
    window.scrollTo(0,0);
    }


}
