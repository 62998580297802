<div role="main">


<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/jobDetails.jpg" alt="First slide">
    </div>
  </div>
</div>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb no-gutters">
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
      </a>
    </li>
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="News" routerLink="/archivejobs" >Archive Jobs List
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Archive Job Details
    </li>
  </ol>
</nav>
<div class="padtop">
<div class="jobDetails">
      <div class="jobDiscription">
          <div class="jobTitle just mobwidth">
              <h1>{{JobTitle}}</h1>
          </div>
          <div class="postedDate mobwidth">
              <p class="dateText">
                  Date Posted: <span class="fromDate">{{StartDate | date: 'MM/dd/yyyy'}} - {{EndDate | date: 'MM/dd/yyyy'}}</span>
              </p>
          </div>
      </div>
      <div class="jobDiscription"  #div>
        <h2>
          Job Duties:
        </h2>
        <!-- P tag for jo duties will append here -->
          
      </div>
      <div class="jobDiscription" #div2>
        <h2>
          Job Requirement:
        </h2>
       <!-- P tag for jo duties will append here -->
      </div>
      <div class="jobDiscription">
          <button type="submit" class="col-md-6 btn btn-primary bigbtn" (click)="Applyjob()" >Apply Job</button>
          <button type="reset" class="col-md-6 btn btn-cancel bigbtn"  (click)="Canceljob()" >Cancel Job</button>
        </div>
  </div>
</div>
</div>