<!-- Main Navigation + LOGO Area -->
<!-- Banner -->
<div role="main">
<header class="innner-page">
    <div class="container">
      <h1><span class="fa fa-angle-double-right" >Dashboard</span></h1>
    </div>
  </header>
  <!-- About Us -->
  <!-- Pre Loader -->
  <div class="loading" style="display: none;">
    <div class="loader"></div>
  </div>
  
  <section class="konnect-news red">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-4 text-center padding " >
          <a  routerLink="/bloglist" >
          <img class="img-fluid" src= "assets/images/Admin/blogs.png" alt="Blog Image" height="200px" width="200px" >
        </a>
        </div>
        <div class="col-sm-12 col-md-4 text-center padding " >
          <a  routerLink="/archivejoblist">
          <img class="img-fluid archjobimagesize" src= "assets/images/Admin/archivejob.png" alt="Archivejob Image" height="180px" width="190px" >
        </a>
        </div>
        <div class="col-sm-12 col-md-4 text-center padding">
          <a  routerLink="/newslist" >
          <img class="img-fluid" src= "assets/images/Admin/news.png" alt="News Image" height="200px" width="200px">
        </a>
        </div>
      </div>
        <div class="row">
        <div class="col-sm-12 col-md-6 text-center padding pd-l">
          <a  routerLink="/ourteamlist">
          <img class="img-fluid" src= "assets/images/Admin/ourTeam.png" alt="OurTeam Image" height="200px" width="200px">
        </a>
        </div>
        <div class="col-sm-12 col-md-6 text-center padding pd-r">
          <a  routerLink="/gallerylist" >
          <img class="img-fluid" src="assets/images/Admin/gallery.png" alt="Gallery Image" height="190px" width="200px">
        </a>
        </div>
      </div>
    </div>
  </section>

</div>
  
         