import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  topnavmobile: boolean;
  constructor() { }

  ngOnInit(): void {
  }
  toggleNavbar() {
  
    this.topnavmobile = !this.topnavmobile;
  }
  
  navSts:any=true;
  redirect(){
    this.checkMob2();
    if(this.navSts==true){
      $('#topnavmobile').removeClass('show');
    }else{
      
  $('#topnavmobile').addClass('show');
   }
   this.navSts=!this.navSts;
    window.scrollTo(0,0);
    }

    
    navSts1:any=true;
    mobilenav(){
      this.checkMob();
      if(this.navSts1==true){
        $('#topnavmobile1').removeClass('show');
      }else{
        $('#topnavmobile1').addClass('show');
     }
     this.navSts1=!this.navSts1;
      window.scrollTo(0,0);
      }
      checkMob(){
        this.navSts1= true ;
        $('#topnavmobile').removeClass('show');
      }
      checkMob2(){
        this.navSts= true;
        $('#topnavmobile1').removeClass('show');
      }
      removeactive(){
      
        $('#topnavmobile').removeClass('active');
      }
}
