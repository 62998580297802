<div role="main">
<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/newsDetails.jpg" alt="First slide">
        <div class="container">
          <div class="carousel-caption banner1 text-left">
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb no-gutters">
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
        </a>
      </li>
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="News" routerLink="/news" >News List
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">News Details
      </li>
    </ol>
  </nav>
  <section class="section-padding-ash">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="blog-post-cont"> <img *ngIf ="imgURL"  [src]="imgURL" height="400" width="1000" alt="News Image">
                <div class="text-cont">
                  <h1><a>SpinoInc</a> | <a>{{newsDate}}</a></h1>
                  <h2 class="just"><a>{{newsTitle}}</a></h2>
                  <p class="just">{{newsDescription}}</p>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf= "nodata == false">
            <div class="sidebar">
              <div class="sidebar-item">
                  <h2>Recent News</h2>
                  <hr/>
                  <ul  *ngFor="let news of NewsList| slice:0:5">
                    <li><a class="latest-blog-single-img "><a class="cursor" (click)="OpenNewsToLeftSide(news)"><img  *ngIf ="news.ImagePath"  [src]="news.ImagePath" class="mobileimg img-responsive" alt="SpinoInc Image" ></a></a></li>
                      <li (click) ="OpenNewsToLeftSide(news)"><a class="cursor"><p class="leftmenu">{{news.ArticleTitle| slice:0:50}}..</p></a></li>
                  </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>