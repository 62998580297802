<div role="main">


<div>
  <img class="img-fluid" src="assets/images/Token-Management-sys.png" alt="Spino Image" >
</div>
<div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Token Management System
      </li>
    </ol>
  </nav>
</div>
<div class="container-fluid p-4">
  <h1 aria-label="heading"></h1>
  <br>
  <div class="font-size"><b>Token Management System : </b></div>
  <br>
  <p>A token management system, also known as a queue management system, is a software to manage crowd/queues
    efficiently by issuing tokens in customer-facing businesses and departments. It reduces wait times for customers and
    makes service smooth.</p>
  <p>Token Management System eliminates long queues, speeds up the transaction process for the employees, and reduces
    the hassle of to manage s large crowd. Additionally, what makes Queue Management System unique is that it can be
    integrated with Digital Signage which thereby allows showing additional content with a token management system.</p>

  <div class="Side-title">Web-Based / Mobile Based Solution:</div>
  <p>
    Our Queue Management System can be accessed efficiently via a web-based solution from any tab, mobile, or computer
    by an admin or any other user via the internet/intranet.
  </p>
  <div class="Side-title">Token Generation:</div>
  <p>
    Token Management System generates customized tokens as per the service to be availed & automatically populate queues
    at the service counters.
  </p>
  <div class="Side-title">Missed Token Recall:</div>
  <p>
    This token generation system recalls no show tokens of customers who may have, for whatsoever reasons, missed their
    token call earlier.
  </p>
  <div class="Side-title">Features</div>
  <ul class="font-color mr-left">
    <li> Easy Maintenance</li>
    <li> High Brightness & Contrast</li>
    <li> Long Life</li>
  </ul>
</div>
</div>