<div role="main">
<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/aboutUs.jpg" alt="First slide">
    </div>
  </div>
</div>
<div >
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">About Us
      </li>
    </ol>
  </nav>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-6"><img class="img-fluid abt-img " src="assets/images/aboutUsCont.jpg" alt="about"></div>
      <div class="col-lg-6">
        <div class="about-3-text">
        
          <h1>Story of SpinoInc</h1>
          <blockquote class="just">SpinoInc is a privately owned company was founded in 2012 by former three Senior Level IT Solutions Professionals and Senior level Talent Acquisition specialists. We are one of the leaders in staffing, IT training and solutions in Middlesex county,
            New Jersey and have a supportive location offshore.</blockquote>
          <p class="just">Since 2012 SpinoInc has established long-term relationships with a variety of companies in different domains and dimension sizes. We view relationships with their clients as partnerships, understanding their client’s business model and challenges.</p>
          <p class="just">We assist defining client’s Strategic Planning including a SWOT Analysis and using a sound technique IT solutions to implement quality control measures, risk management techniques, and continue to monitor to ensure that lean principals are enforced with efficient and effective results.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-caption">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="about-caption">
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-accordian">
  <div class="container">
  <div class="row">
    <div class="col-lg-12">
      <div id="accordion">
  <div class="card">
    <div class="card-header btn-link cursor" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      <span class="mb-0 ">
        <a class="btn h5heading">
         Current Clients 
        </a>
      </span>
    </div>

    <div id="collapseOne" class="collapse show" data-parent="#accordion">
      <div class="card-body just">
        Currently we assistance fortune 500 companies with their internal & external challenges. Providing small to medium size firms with the support to facilitate growth in a Geo-economic environment. No project is too small or too large for us as we value relationships more than the magnitude of a project.
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header btn-link cursor" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
      <span class="mb-0 ">
        <a class="btn h5heading">
          Where does it comes from?
        </a>
      </span>
    </div>
    <div id="collapseTwo" class="collapse" data-parent="#accordion">
      <div class="card-body just">
        We are geared toward continuing culturalization’s of new relationships and increase cost-effective services to our existing client base. We understand trust is everything in a relationship. We would like to earn your business the traditional way by earning your trust first.
      </div>
    </div>
  </div>
  <div class="card">
    <div  class="card-header btn-link cursor" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
      <span class="mb-0 ">
        <a class="btn h5heading">
          What should we do now?
        </a>
      </span>
    </div>
    <div id="collapseThree" class="collapse"  data-parent="#accordion">
      <div class="card-body just">
        Give us a call to see how we can leverage your resources while providing excellent ROI. Recognized by industry peers as a leading force in the 21st century, providing current/relevant IT solutions.
      </div>
    </div>
  </div>
</div>
    </div>
  </div>  
  </div>
</section>
</div>