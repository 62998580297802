import { Component, OnInit, Input } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { ServerService } from 'src/app/shared/services/server.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model: any ={};
  AccountId : any ={};
  EmailAddress : string;
  password : string;
  captcha : any;
  Password : string;
  logInForm: FormGroup;
  submitted = false;
  success: boolean =false;
  Fail: boolean = false;
  errorMessage:any;
  successMessage = false;
  memberName : any;
  recaptcha :  any;

  constructor(
    public router: Router,
    private http: HttpClient,
    private serverService: ServerService,
    private authService: AuthenticationService,

  ) { }
  ngOnInit() {
  }
  resolved (captchaResponse: any[]){
    this.recaptcha = captchaResponse;
      }
  onLogin(f) {
   // localStorage.setItem('adminID', '2');
    this.success= false;
    this.Fail=false;
    this.model.AccountId= 2;
    this.model.EmailAddress=this.model.EmailAddress;
    this.model.Password=this.model.Password;
    this.authService.Login(this.model)
      .subscribe(
        data => {
          debugger
           if (data['Response'].status === '200') {
            // if (data['LoginData'].MemberId == 2) {
              localStorage.setItem('adminID', '2');
              this.memberName = data['LoginData'].MemberName;
           // }
            // window.location.reload();
            //this.router.navigate(['/dashboard'])
            this.success= true;
              setTimeout(()=>{
                this.success=false;
                this.router.navigateByUrl("/dashboard")
                .then(() => {
                  window.location.reload();
                })
              },1000)
           
          }
          else {
            this.Fail=true;
            setTimeout(()=>{
              this.Fail=false;
            },3000)
          }
        },
        error => {
          console.log(error)
        }
      )
  }

  redirect(){
window.scrollTo(0,0);
}


}
