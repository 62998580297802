<header class="innner-page" *ngIf="newORupdate">
    <div class="container">
      <h1><i class="fa fa-angle-double-right" aria-hidden="true"></i>Add Archive Jobs
      </h1>
    </div>
  </header>
  <nav aria-label="breadcrumb" *ngIf="newORupdate">
    <ol class="breadcrumb no-gutters">
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="Dashboard" routerLink="/dashboard" skipLocationChange>Home
        </a>
      </li>
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="News" routerLink="/archivejoblist" skipLocationChange>Archive Job List
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Add Archive Job
      </li>
    </ol>
  </nav>
  <div class="container" *ngIf="newORupdate" role="main" > 
    <div class="alert alert-success" *ngIf="Success">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <p>{{ successMessage }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="Fail">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <p>{{ errorMessage }}</p>
    </div>
  <div class="col-md-12 admpad">
    <form #f="ngForm" name="form" aria-label="form" class="pure-form pure-form-stacked formborder" (ngSubmit)="f.form.valid"
    novalidate>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <div class="row">
            <!-- START DATE -->
            <div class="col-md-4">
              <label class="col-form-label"><span class="h6">Start Date :</span></label>
            </div>
            <div class="col-md-8">
              <input type="date" class="form-control" name="startdate" [(ngModel)]="model.startdate" #startdate="ngModel"
              placeholder="Start Date" [ngClass]="{ 'is-invalid': f.submitted && startdate.invalid }" required />
            <div *ngIf="(startdate.touched ||f.submitted) && startdate.invalid" class="invalid-feedback">
              <div *ngIf="startdate.errors.required">Date is required</div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Date -->
      <div class="col-md-6">
        <div class="form-group">
          <div class="row">
            <!-- START DATE -->
            <div class="col-md-4">
              <label class="col-form-label"><span class="h6">End Date :</span></label>
            </div>
            <div class="col-md-8">
              <input type="date" class="form-control" name="enddate" [(ngModel)]="model.enddate" #enddate="ngModel"
              placeholder="End Date" [ngClass]="{ 'is-invalid': f.submitted && enddate.invalid }" required />
            <div *ngIf="(enddate.touched ||f.submitted) && enddate.invalid" class="invalid-feedback">
              <div *ngIf="enddate.errors.required">Date is required</div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- JOB TITLE -->
    <div class="col-md-12">
   <div class="form-group">
    <span class="h6">Title :</span>
    <select type="text" class="form-control" name="title" [(ngModel)]="model.title" #title="ngModel"
      placeholder="Job Title" [ngClass]="{ 'is-invalid': f.submitted && title.invalid }" required >
      <option  value="undefined" disabled>Choose Title</option>
      <option *ngFor="let j of JobList" [value]="j.title">
          {{ j.title }}
      </option>
    </select>
    <div *ngIf="(title.touched ||f.submitted) && title.invalid" class="invalid-feedback">
      <div *ngIf="title.errors.required">Job Title is required</div>
    </div>
  </div>
    </div>
      <!-- Job role  -->
    <div class="col-md-12">
    <div class="form-group">
      <span class="h6">Role :</span>
     <ckeditor name="jobrole"
      [(ngModel)]="model.jobrole"  required  config.basicEntities = false; [ngClass]="{ 'is-invalid': f.submitted && jobrole.errors }" 
       #jobrole="ngModel"  placeholder="Job Role" ></ckeditor>
      <div *ngIf="f.submitted && jobrole.invalid" class="invalid-feedback">
        <div *ngIf="jobrole.errors.required">Enter Job duties</div>
        <div *ngIf="jobrole.errors.maxlength">Description should not exceed 4000 letters</div>
      </div> 
    </div>
    </div>
         <!-- JOB REQUIRMENT -->
    <div class="col-md-12">
         <div class="form-group">
          <span class="h6">Requirement :</span>
          <ckeditor  name="jobrequirement"  [(ngModel)]="model.jobrequirement" required             
            [ngClass]="{ 'is-invalid': f.submitted && jobrequirement.errors }" #jobrequirement="ngModel"></ckeditor>
          <div *ngIf="f.submitted && jobrequirement.invalid" class="invalid-feedback">
            <div *ngIf="jobrequirement.errors.required">Job Requirement is empty</div>
            <div *ngIf="jobrequirement.errors.maxlength">Job Requirement should not exceed 4000 letters</div>
          </div>
        </div>
      </div>
    <div class="col-md-8 padbtm">
      <button type="submit" class="col-md-3 btn btn-primary" [attr.data-show] = "f.form.valid? 'true': 'false' " data-toggle="modal" data-target="#myModalSubmit">Submit</button>
      <button type="reset" class="col-md-3 btn btn-cancel" (click)="CancelArchiveJob()">Cancel</button>
    </div>
    </form>
   </div>
</div> 


<!-- ****************UDATE OR EDIT JOB ************** -->
<header class="innner-page" *ngIf="!newORupdate">
  <div class="container">
    <h1><i class="fa fa-angle-double-right" aria-hidden="true"></i>Update Job</h1>
  </div>
</header>
<nav aria-label="breadcrumb"  *ngIf="!newORupdate">
  <ol class="breadcrumb no-gutters">
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="Dashboard" routerLink="/dashboard" skipLocationChange>Home
      </a>
    </li>
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="jobs" routerLink="/archivejoblist" skipLocationChange>Archive Job List
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Update Archive Job
    </li>
  </ol>
</nav>
<section class="contact-map" *ngIf="!newORupdate">
  <div class="container"  role="main">
    <div class="alert alert-success" *ngIf="Success">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <p>{{ successMessage }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="Fail">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <p>{{ errorMessage }}</p>
    </div>
    <div class="row">
      <div class="col-md-12 margin-bottom-20">
        <!-- <h2 class="section-heading text-dark">Update <span> Job</span></h2> -->
        <div class="konnect-space"></div>
        <div class="col-md-12">
        <form #f="ngForm" name="form" class="pure-form pure-form-stacked formborder" (ngSubmit)="f.form.valid && onSubmit(f,'')"
        novalidate>
        <div class="row">
          <div class="col-md-4 col-lg-6"></div>
          <div class="col-md-8 col-lg-6 col-sm-12 Buttonalign ">
            <div class="float-right">
              <button type="submit" class="konnect-submit">Submit</button>&nbsp;&nbsp;
              <button type="reset" class="konnect-submit" (click)="Cancel()">Cancel</button>&nbsp;&nbsp;
              <button type="button" class="konnect-submit" data-toggle="modal" data-target="#myModal">Delete</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="row">
                <!-- START DATE -->
                <div class="col-md-4">
                  <label class="col-form-label">
                    <span class="h6">Start Date :</span></label>
                </div>
                <div class="col-md-8">
                  <input type="date" class="form-control" name="startdate" [(ngModel)]="model.startdate" #startdate="ngModel"
              placeholder="Start Date" [ngClass]="{ 'is-invalid': f.submitted && startdate.invalid }" required />
            <div *ngIf="(startdate.touched ||f.submitted) && startdate.invalid" class="invalid-feedback">
              <div *ngIf="startdate.errors.required">Date is required</div>
            </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Date -->
          <div class="col-md-6">
            <div class="form-group">
              <div class="row">
                <!-- START DATE -->
                <div class="col-md-4">
                  <label class="col-form-label">
                    <span class="h6">End Date :</span></label>
                </div>
                <div class="col-md-8">
                  <input type="date" class="form-control" name="enddate" [(ngModel)]="model.enddate" #enddate="ngModel"
              placeholder="End Date" [ngClass]="{ 'is-invalid': f.submitted && enddate.invalid }" required />
            <div *ngIf="(enddate.touched ||f.submitted) && enddate.invalid" class="invalid-feedback">
              <div *ngIf="enddate.errors.required">Date is required</div>
            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         <!-- JOB TITLE -->
        <div class="col-md-12">
       <div class="form-group">
        <span class="h6">Title :</span>
        <input type="text" class="form-control" name="title" [(ngModel)]="model.title" #title="ngModel"
        placeholder="Title" [ngClass]="{ 'is-invalid': f.submitted && title.invalid }" required />
        <div *ngIf="(title.touched ||f.submitted) && title.invalid" class="invalid-feedback">
          <div *ngIf="title.errors.required">Title is required</div>
        </div>
      </div>
        </div>
          <!-- Job role  -->
        <div class="col-md-12">
        <div class="form-group">
          <span class="h6">Role :</span>
          <ckeditor name="jobrole" 
              [(ngModel)]="model.jobrole" required  [ngClass]="{ 'is-invalid': f.submitted && jobrole.errors }" 
               #jobrole="ngModel"  ></ckeditor>
          <div *ngIf="f.submitted && jobrole.invalid" class="invalid-feedback">
            <div *ngIf="jobrole.errors.required">Enter Job duties</div>
            <div *ngIf="jobrole.errors.maxlength">Description should not exceed 4000 letters</div>
          </div> 
        </div>
        </div>
             <!-- JOB REQUIRMENT -->
        <div class="col-md-12">
             <div class="form-group">
              <span class="h6">Requirement :</span>
              <ckeditor  name="jobrequirement" [(ngModel)]="model.jobrequirement" required             
               [ngClass]="{ 'is-invalid': f.submitted && jobrequirement.errors }" #jobrequirement="ngModel"></ckeditor>
              <div *ngIf="f.submitted && jobrequirement.invalid" class="invalid-feedback">
                <div *ngIf="jobrequirement.errors.required">Job Requirement is empty</div>
                <div *ngIf="jobrequirement.errors.maxlength">Job Requirement should not exceed 4000 letters</div>
              </div>
            </div>
          </div>
        </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- *********************MODAL Alert TO Delete confirmation ***************** -->
<div id="myModal" class="modal fade">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header">
        <div class="icon-box">
          <i class="fa fa-times"></i>
        </div>
        <h4 class="modal-title">Are you sure?</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <p>Do you really want to delete this record? This process cannot be undone.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="Delete()">Delete</button>
      </div>
    </div>
  </div>
</div>




<div id="myModalSubmit" class="modal fade" role="alertdialog" aria-label="alertbox">
  <div class="modal-dialog modal-confirm">
      <div class="modal-content">
          <div class="modal-header">
              <div class="icon-box">
                  <i class="fa fa-thumbs-up"></i>
              </div>				
              <h3 class="modal-title">Are you sure?</h3>	
              
              <button type="button" class="close" data-dismiss="modal" aria-expanded="false" aria-label="close">&times;</button>
          </div>
          <div class="modal-body">
              <p>Do you really want to Add this record</p>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onSubmit(model, 'Yes')" >Add</button>
          </div>
      </div>
  </div>
</div> 
