
<div role="main">


<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/ourTeam.jpg" alt="First slide">
    </div>
  </div>
</div>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Our Team
    </li>
  </ol>
</nav>
<section class="konnect-clients bodyclass" aria-label="team">
  <div class="container ">
    <div>
      <h2 class="Executive-Leadership-Team" style="margin-top:15px"> Executive Leadership Team</h2>
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card ">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="../../../assets/images/employees/shankar-katkala.png" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name">Shankar Katkala</h2>
            <p class="occ">Founder</p>
            <!-- <div class="row text-center mar">
            <div class="mar-auto">
              <a class="mr10"><img src="../../../assets/images/twitter.png"></a>
              <a class="mr10"><img src="../../../assets/images/facebk.png"></a>
              <a ><img src="../../../assets/images/in.png"></a>
            </div>
           
          </div> -->
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card ">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="../../../assets/images/employees/tarwinder-singh.png" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name">Tarwinder Singh</h2>
            <p class="occ">Founder</p>

          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card ">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="../../../assets/images/employees/alok-kumar.png" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name">Alok Kumar</h2>
            <p class="occ">Founder</p>



          </div>
        </div>


      </div>
    </div>

    <div>
      <h2 class="Executive-Leadership-Team">Corporate Services & Technical</h2>
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="../../../assets/images/employees/william-scott.jpg" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name">William Scott Scharnikow</h2>
            <p class="occ">
              Sr. Business Development Manager</p>

          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="../../../assets/images/employees/michael-diorio.png" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name">Michael Diorio</h2>
            <p class="occ">
              VP of Business Development </p>

          </div>
        </div>
        <!-- <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="../../../assets/images/employees/ashish-mehta.png" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name">Ashish Mehta</h2>
            <p class="occ">
              Business Development Manager </p>

          </div>
        </div> -->
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="https://live.api.ubsols.com/images/OurTeam/74/rajesh.jpg"
                  alt="Image" style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name">Rajesh Kumar</h2>
            <!-- <h2 class="name mpt-0" style="visibility: hidden;">(Stanly)</h2> -->
            <p class="occ">
              HR/Delivery Manager </p>

          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="https://live.api.ubsols.com/images/OurTeam/63/praveer.jpg"
                  alt="Image" style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name">Praveer Panghal</h2>
            <p class="occ">
              IT & Infrastructure Manager </p>

          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="../../../assets/images/employees/dennis-white.png" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name">Dennis White</h2>
            <p class="occ">
              Sr. VP  of Business Development </p>

          </div>
        </div>
      </div>
    </div>

    <div>
      <h2 class="Executive-Leadership-Team">Recruiting & Delivery</h2>
      <div class="row">
        
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="https://live.api.ubsols.com/images/OurTeam/17/G.S.-Habibulla.jpg"
                  alt="Image" style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name mpb-0">Habibulla Ganja</h2>
            <h2 class="name mpt-0">(Stanly)</h2>
            <p class="occ">Recruitment Manager</p>

          </div>
        </div>
        <!-- <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="../../../assets/images/employees/jr-william-scott.png" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name mpb-0">Jr. William Scott Scharnikow </h2>
            <h2 class="name mpt-0">(Billy)</h2>
            <p class="occ"> Account Manager </p>

          </div>
        </div> -->
        <!-- <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="../../../assets/images/employees/nistha-chakraborty.png" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name mpb-0">Nistha Chakraborty</h2>
            <h2 class="name mpt-0" style="visibility: hidden;">(Stanly)</h2>
            <p class="occ"> Business Development Manager</p>

          </div>
        </div> -->
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="https://live.api.ubsols.com/images/OurTeam/24/Shiva-Kumar.jpg" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name mpb-0">Kakkerla Shiva </h2>
            <h2 class="name mpt-0">(David)</h2>
            <p class="occ"> Lead Sales Recruiter</p>

          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="https://live.api.ubsols.com/images/OurTeam/26/Arun-Kumar.jpg" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name mpb-0">Arun Nalgindla </h2>
            <h2 class="name mpt-0">(Paul)</h2>
            <p class="occ"> Lead IT Recruiter</p>

          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="https://live.api.ubsols.com/images/OurTeam/16/Nalla-Saivivek.jpg" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name mpb-0">Nalla Sai Vivek</h2>
            <h2 class="name mpt-0">(Edward)</h2>
            <p class="occ"> Lead IT Recruiter</p>

          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="https://live.api.ubsols.com/images/OurTeam/18/Vatti-Ajith-Kumar.jpg" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name mpb-0">Ajith Kumar</h2>
            <h2 class="name mpt-0">(Chris)</h2>
            <p class="occ"> Lead IT Recruiter</p>

          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="https://live.api.ubsols.com/images/OurTeam/21/Sampath.jpg"
                  alt="Image" style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name mpb-0">Patha Sampath </h2>
            <h2 class="name mpt-0" style="visibility: hidden;">(Stanly)</h2>

            <p class="occ"> Lead Sales Recruiter</p>

          </div>
        </div>
        
        <div class="col-md-4 col-sm-12">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" src="../../../assets/images/employees/bharath-reddy.jpg" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name mpb-0">Bharath Reddy</h2>
            <h2 class="name mpt-0" style="visibility: hidden;">(Stanly)</h2>

            <p class="occ"> Lead IT Recruiter</p>

          </div>
        </div>
        
      </div>
    </div>

    <div>
      <h2 class="Executive-Leadership-Team">Software Development</h2>
      <div class="row" *ngIf="nodata==false">
        <div class="col-md-4 col-sm-12" *ngFor="let user of TeamList">
          <div class="rectangle-card">
            <div class="row   text-center Rectangle-2" data-toggle="modal" data-target="#myModal"
              (click)="ViewTeam(user)">
              <div class="img-div rounded ">
                <img class="Layer-3 img-circle" *ngIf="user.ImagePath" [src]="user.ImagePath" alt="Image"
                  style='object-fit: fill !important;'>
              </div>
            </div>
            <h2 class="name">{{user.EmpFullName}}</h2>
            <p class="occ">{{user.EmpTitle}}</p>

          </div>
        </div>
      </div>
      <div *ngIf="nodata==true">
        <h6 class="Nodata"> Team Data Not Found</h6>
      </div>
    </div>
    <!-- <div class="row" *ngIf="nodata==false">
        <div class="ourTeamCont col-md-12">
          <ul class="slideInLeft_animation">
            <li *ngFor="let user of TeamList">
              <div class="teamMemberCont" data-toggle="modal" data-target="#myModal" (click)="ViewTeam(user)">
                <div class="teamMember">
                  <a> <img *ngIf="user.ImagePath" [src]="user.ImagePath" alt="Image" height="120" width="120" /></a>
                </div>
                <h2 class="cursor">{{user.EmpFullName}}</h2>
                <p class="cursor color">{{user.EmpTitle}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="nodata==true">
        <h6 class="Nodata"> Team Data Not Found</h6>
      </div> -->
  </div>


  <!-- MODAL FOR VIEWING EMPLOYEE FULL DETAILS -->
  <!-- <div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog">
      
      <div class="modal-content" id="dialogueBox">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="dialogueBoxCont ex1">
            <div class="teamMember imgmob">
              <img [src]="imgURL" alt="Team Member" height="120" width="120" />
            </div>
            <h5>{{TeamName}}</h5>
            <h5 class="fontColor mob"><b>{{TeamTitle}}</b></h5>
            <div>
              <p class="ourteamRole">{{role}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="modal ourTeam padtop" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-team">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <div class="profileWizard">
            <div class="profilePic">
              <img *ngIf="imgURL" [src]="imgURL" alt="Image" height="120" width="120" />
            </div>
            <h6 class="font-weight-bold">{{TeamName}}</h6>
            <h6 class="alignMarginTop technology"><span style="color: #777;">{{TeamTitle}}</span></h6>
          </div>
        </div>
        <div class="modal-body">
          <p class="just rolepadding">{{role}}</p>
        </div>

      </div>
    </div>
  </div>
</section>
<!-- <section>
  <div class="container team-row">
    <div class="ourTeamCont col-md-12 sec-padding" *ngIf="nodata==false">
         <ngx-spinner
         bdColor="rgba(51,51,51,0.8)"
         size="default"
         color="#fd6121"
         type="line-scale-pulse-out-rapid"
         [fullScreen] = "false" > </ngx-spinner>
      <ul>
       
        <li *ngFor="let user of TeamList">
          <div class="teamMemberCont" data-toggle="modal" data-target="#myModal" (click)="ViewTeam(user)">
            <div class="teamMember">
              <a> <img   *ngIf= "user.ImagePath" [src]="user.ImagePath" alt="Team Member" height="120" width="120" /></a>
            </div>
            <h1 class="cursor">{{user.EmpFullName}}</h1>
            <p class="cursor color">{{user.EmpTitle}}</p>
          </div>
        </li>
      </ul>
      <div  *ngIf="nodata==true" >
             <div class="Nodata"> Team Data Not Found</div>
             </div>
      <div class="modal ourTeam padtop" id="myModal" >
        <div class="modal-dialog">
          <div class="modal-content">
          
            <div class="modal-team">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
          <div class="profileWizard">
                  <div class="profilePic">
                    <img *ngIf= "imgURL"  [src]="imgURL"  alt="Team Member" height="120" width="120" />
                  </div>
                  <h2 class="font-weight-bold">{{TeamName}}</h2>
                  <h2 class="alignMarginTop technology"><span style="color: #707070;">{{TeamTitle}}</span></h2>
                </div>
            </div>
            
            <div class="modal-body">
              <p class="just rolepadding">{{role}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

</div>
