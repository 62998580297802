import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ServerService } from 'src/app/shared/services/server.service';
import { ImagevalidationService } from 'src/app/shared/services/imagevalidation.service';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {

  model:any ={};
  Resumemodel: any={};
  Success:any;
      Fail: any;
      resume = true;
      errorMessage: any;
      successMessage: any;
  blob;
  filestream: any;
  Validate: any;
  imageChangedEvent: any = '';
  selectedFile: File = null;
  public imagePath;
  imgURL: any;
  SpecializationList: any;
  fileInfo: string;
  @ViewChild('f') formValues;
  
    constructor( private filevalidation: ImagevalidationService , private serverService: ServerService, //private location: Location 
      ) { }
  
    ngOnInit() {
      this.FetchSpecialization();
      //this.location.replaceState('');
    }
  
    FetchSpecialization(){
    
      this.model.AccountId=1
   this.serverService.FetchSpecialization(this.model).subscribe((data: any) => {
     
     this.SpecializationList=data.Specialization;
    //  console.log(this.SpecializationList);
   })
     }
  
     // method for select file
     fileChangeEvent(event: any): void {
      try {
        if(this.fileInfo == ''){
          this.resume= true;
  
        } else {this.resume= false;}
  
        this.Validate = this.filevalidation.valiadtepdfdoc(event);
       
        // validation will be get done here
        if (this.Validate === true) {
  
          const file = event.target.files[0];
          this.fileInfo = `${file.name}`;    
          this.imageChangedEvent = event;
          // tslint:disable-next-line
          this.selectedFile = event.target.files[0];
          var reader = new FileReader();
          this.imagePath = event.target.files;
          reader.readAsDataURL(event.target.files[0]); 
          reader.onload = (_event) => { 
            this.imgURL = reader.result;  
            fetch(this.imgURL)
            .then(res => res.blob())
            .then(blob => {
              const fd = new FormData();
              fd.append('image', blob, 'filename');
              this.blob = blob;
            });
           }
        } else if(this.Validate===0) {
          this.Fail = true;
          this.errorMessage = "Please Select file";
         setTimeout(() => {
           this.Fail = false;
           this.errorMessage = '';
         }, 10000);
       }
       else if (this.Validate==false) {
        this.Fail = true;
        this.errorMessage = "Resume must be : pdf, doc or docx";
       setTimeout(() => {
         this.Fail = false;
         this.errorMessage = '';
       }, 10000);
       }
       else{
        this.Fail = true;
        this.errorMessage = "Select Valid File";
       setTimeout(() => {
         this.Fail = false;
         this.errorMessage = '';
       }, 10000);
       }
  
     } catch (error) {
       throw error;
     }
  
   }
   onimageload() {
    const fileData: any = {};
    fileData.data = this.selectedFile['name'];
    this.blob.name =  fileData.data
    this.blob.webkitRelativePath = '';
    fileData.fileStream = this.blob;
   
  return fileData.fileStream
  
  }
  
  onSubmit(f:NgForm) {
         const fileData:any ={};
        // this.Resumemodel.ArticleTitle=this.model.title;
        
        // this.Resumemodel.ArticleDescription=this.model.description;
        fileData.fileStream =this.onimageload();
        //this.Blogmodel.fileStream='';
    
      this.Resumemodel.fileName=  fileData.fileStream.name;
      this.Resumemodel.FirstName=this.model.firstName;
      this.Resumemodel.LastName=this.model.lastName;
      this.Resumemodel.PhoneNumber =this.model.phoneNumber;
      this.Resumemodel.ZipCode =this.model.zipCode;
      this.Resumemodel.EmailAddress =this.model.email;
      this.Resumemodel.Specialized =this.model.Specialized;
     
      // console.log(fileData.fileStream);
      // console.log(this.Resumemodel)
  this.serverService.ResumePosting(this.Resumemodel, fileData.fileStream).subscribe(res=>{
    if(res['Result']=="Email sent successfully!")
  
    {
    
    this.Success=true;
    // this.successMessage=res['Result'];
    this.successMessage="We will get in touch , Shortly!";
    setTimeout(() => {
           this.Success = false;
           this.successMessage = '';
         }, 3000);
        }
        else {
          this.Fail=true;
    this.errorMessage=res['Result'];
    setTimeout(() => {
      this.Fail = false;
      this.errorMessage = '';
    }, 3000);
        }
  });
  this.ResetForm();
  }
  
  ResetForm(){
    
    this.formValues.resetForm();
    this.imgURL='';
    this.fileInfo='';
  }
  }
  