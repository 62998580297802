
<div role="main">


<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/contactUs.jpg" alt="First slide">
    </div>
  </div>
</div>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Contact Us
    </li>
  </ol>
</nav>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="contact-left">
          <h1>Submit Your Message</h1>
          <div class="notification">
            <div class="alert alert-success" *ngIf="success">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <p>{{ successMessage }}</p>
            </div>
            <div class="alert alert-danger" *ngIf="Fail">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <p>{{ errorMessage }}</p>
            </div>
          </div>
          <div class="form-cont">
            <form #f="ngForm" name="form" class="pure-form pure-form-stacked" (ngSubmit)="f.form.valid && onSubmit(f)"
            novalidate>
            <div class="form-group">
              <input type="text" class="form-control" name="name" [(ngModel)]="model.name"
               #name="ngModel" placeholder="Enter Your Name*"
                [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required="required"  required />
              <div *ngIf="(name.touched ||f.submitted) && name.invalid" class="invalid-feedback">
                  <div *ngIf="name.errors.required">Name is required</div>
              </div>
          </div>
            <div class="form-group">
              <input type="text" class="form-control" name="phone" [(ngModel)]="model.phone" 
              #phone="ngModel" placeholder="Enter Your Phone Number*" pattern="[0-9]{10}"
               [ngClass]="{ 'is-invalid': f.submitted && phone.invalid }" minlength="10" maxlength="10" required="required" required />
              <div *ngIf="(phone.touched || f.submitted) && phone.invalid" class="invalid-feedback">
                  <div *ngIf=" phone.errors.required">Phone number is required</div>
                  <div *ngIf=" (phone.errors.pattern || phone.errors.minlength)">Enter valid phone number</div>
              </div>
          </div>
            <div class="form-group">
              <input type="text" class="form-control" name="email" [(ngModel)]="model.email" placeholder="Enter Your E-Mail*"
              #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email />
              <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                  <div *ngIf="(email.untouched ||email.errors.required)">Email is required</div>
                  <div *ngIf="email.touched && email.errors.email">Email must be a valid email address</div>
              </div>
          </div>
            <div class="form-group">
              <textarea class="form-control"  name="comment" [(ngModel)]="model.comment"
              [ngClass]="{ 'is-invalid': f.submitted && comment.invalid }"
                placeholder="Comment here..." required #comment="ngModel" rows="7"></textarea>
                <div *ngIf="f.submitted && comment.invalid" class="invalid-feedback">
                  <div *ngIf="(comment.untouched || comment.errors.required)">Message is required</div>
                  </div>
            </div>
            <re-captcha [(ngModel)]="model.captchaValue" name="captcha" #captcha required></re-captcha>
                       <br/>
            <div class="col-md-12">
              <button type="submit" [disabled]="f.form.invalid" class="btn btn-primary ">Submit</button>
            </div>
          </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
       
        <div class="contact-right">
          <h1>REACH US</h1>
         
          <div class="contact-info marign-top">
            <img src="assets/images/USA.png" alt="icon"><b> USA</b>
            <div class="row">
              
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="text-cont">
                    <h2>Address:</h2>
                    <p>1100 Cornwall Rd, Suite #221, Monmouth Junction, NJ 08852, USA.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="text-cont">
                    <h2>Phone:</h2>
                    <p> +1-(732)584-7973</p>
                    <h2>Email:</h2>
                    <p>info@spinoinc.com</p>
                  </div>
                </div>
              </div>
              </div>
          </div>
              <img src="assets/images/canada.jpg" alt="icon"><b> Canada</b>
              <div class="contact-info">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="contact-info-item">
                      <div class="text-cont">
                        <h2>Address:</h2>
                        <p>107 Charlotte <br>
                          St. Saint John, NB E2L 2J2, Canada.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="contact-info-item">
                      <div class="text-cont">
                        <h2>Phone:</h2>
                        <p> -</p>
                        <h2>Email:</h2>
                        <p>info@spinoinc.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img src="assets/images/india.png" alt="icon"><b> India</b>
              <div class="contact-info">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="contact-info-item">
                      <div class="text-cont">
                        <h2>Address:</h2>
                        <p>4th Floor, JVR Towers, Near Omni Hospital, 
                          Kothapet, Dilshuknagar, Hyderabad-500035, INDIA.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="contact-info-item">
                      <div class="text-cont">
                        <h2>Phone:</h2>
                        <p>+91- 040-40178844</p>
                        <h2>Email:</h2>
                        <p>info@spinoinc.com</p>
                      </div>
                    </div>
                  </div>
              <!-- <img class="india" src="assets/images/canada.jpg" alt="icon"><b>&nbsp; Canada</b>
              
              <div class="row contact-right">
                <div class="col-lg-8">
                  <div class="contact-info-item">

                    <div class="text-cont">
                      <h6>Address:</h6>
                      <p>New Brunswick, Canada.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="contact-info-item">

                    <div class="text-cont">
                      <h6>Phone:</h6>
                      <p>+91- 040-40178844</p>
                      <h6>Email:</h6>
                      <p>info@spinoinc.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <img class="india" src="assets/images/india.png" alt="icon"><b>&nbsp; India</b>
              <div class="row contact-right">
                <div class="col-lg-6">
                  <div class="contact-info-item">

                    <div class="text-cont">
                      <h6>Address:</h6>
                      <p>4th Floor, JVR Towers, Near Omni Hospital, 
                        Kothapet, Dilshuknagar, Hyderabad-500035, INDIA.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="contact-info-item">

                    <div class="text-cont">
                      <h6>Phone:</h6>
                      <p>+91- 040-40178844</p>
                      <h6>Email:</h6>
                      <p>info@spinoinc.com</p>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <!-- USA MAP ADDRESS -->
      <!-- <div class="konnect-space"></div> -->
      <!-- Contact Form-->
      <div class="col-sm-12 col-md-6">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.641431231771!2d-74.5322214842938!3d40.416793563559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c316638bc311%3A0x41b3f7b10db80558!2s1100%20Cornwall%20Rd%20%23221%2C%20Monmouth%20Junction%2C%20NJ%2008852%2C%20USA!5e0!3m2!1sen!2sin!4v1573468010162!5m2!1sen!2sin"
          aria-label="usamap" alt="no map" title="spinoinc" width="100%" height="300px" frameborder="0" style="border:0" allowfullscreen></iframe>

      </div>


      <!-- INDIA MAP  -->

      <!-- Contact Form-->
      <div class="col-sm-12 col-md-6">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.897054215366!2d78.53672845142442!3d17.36868598803128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb98e91621cce7%3A0xc6fb6e2d978adb81!2sSpino+Technologies+Pvt+Ltd!5e0!3m2!1sen!2sin!4v1475238969427"
          aria-label="hydmap"  alt="no map" title="spino" width="100%" height="300px" frameborder="0" style="border:0" allowfullscreen></iframe>

      </div>
    </div>
  </div>
</section>
</div>