import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServerService } from 'src/app/shared/services/server.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-ourteam-list',
  templateUrl: './ourteam-list.component.html',
  styleUrls: ['./ourteam-list.component.css']
})
export class OurteamListComponent implements OnInit, OnDestroy {

  model: any ={};
  TeamList: any;
  Fail:boolean;
  Success:boolean;
  EmployeeId: any;
  successMessage:any;
  nodata= false;
  errorMessage:any;
  private unsubscribe$ = new Subject<void>();
  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit(): void {
    
    this.FetchTeamList();
  }
  FetchTeamList(){
    this.model.AccountId=2
  
    this.serverService.FetchOurTeam(this.model)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
   (data:any )=>{ 
      // console.log(data);
      this.TeamList=data.OurTeamList;
      if( this.TeamList=="" || this.TeamList.length==0){
              this.nodata=true;
            }
            else{
              this.nodata=false;
            }
   } );

  }
    //to edit and update blog
    upadteTeam(user){
      this.serverService.setteam(user);
      this.router.navigateByUrl('/crudteam')
      
        }
        DeleteTeam(EmployeeId, Status){
    
          this.model={};
           this.model.EmployeeId=EmployeeId;
           if(Status==='Yes'){
           this.serverService.DeleteTeam( this.model).subscribe(
             res=>{
              // debugger;
              //  console.log(res)
                if(res['Response'].status ==200){ 
                 this.Success =true;
                this.successMessage= res['OurTeamData'].ReturnVal;
                setTimeout(() => {
                 this.Success = false;
                 this.successMessage = '';
                         }, 3000);
                        //  this.FetchTeamList();
                }
                else{
                  this.Fail=true;
                  this.errorMessage= 'Error while processing';
                  setTimeout(() => {
                   this.Fail = false;
                   this.errorMessage = '';
                           }, 3000);
                }
               }
               );
              //  this.FetchTeamList();
              }
              this.FetchTeamList();
         }
   ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

