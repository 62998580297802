<div role="main">


<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/blog.jpg" alt="First slide">
      </div>
    </div>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Blog List
      </li>
    </ol>
  </nav>

  <section class="section-padding-ash">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="nodata==false">
          <div class="row">
            <!-- Spinner start -->
            <ngx-spinner
            bdColor="rgba(51,51,51,0.8)"
            size="default"
            color="#fd6121"
            type="line-scale-pulse-out-rapid"
            [fullScreen] = "false" > </ngx-spinner>
             <!-- Spinner end -->
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"  *ngFor="let blog of BlogList; let i = index">
              <div class="blog-post-cont"><a class="cursor" (click)="ViewBlog(blog)"><img [src]="blog.ImagePath" alt="No Image" height="180px;" width="350px;" alt="Spino Blog"></a> 
                <div class="text-cont">
                  <h1><a>SpinoInc</a> | <a>{{blog.ArticleDate}}</a></h1>
                  <div class="h5"><a  (click)="ViewBlog(blog)">{{blog.ArticleTitle | slice:0:30}}..</a></div>
                  <p class="Description just">{{blog.ArticleDescription}}</p>
                  <a   class="readdmore" (click)="ViewBlog(blog)">Read More <i class="icofont icofont-long-arrow-right"></i></a> </div>
              </div>
            </div>
          </div>
        </div>
       <div  *ngIf="nodata==true" >
         <h6 class="Nodata"> No Blogs Found</h6>
         </div>
      </div>
    </div>
  </section>
</div>