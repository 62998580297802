import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {FormsModule } from '@angular/forms';
import{HttpClientModule  } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AnimateSliderModule } from "animated-slider";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from 'ckeditor4-angular';
import { NgxSpinnerModule } from "ngx-spinner";

import {ImageCropperComponent } from 'src/app/components/admin/image-cropper/component/image-cropper.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { Home1Component } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { PortfolioComponent } from './components/pages/clients/clients.component';
import { ConsultingComponent } from './components/pages/consulting/consulting.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogdetailsComponent } from './components/pages/blogdetails/blogdetails.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { LoginComponent } from './components/pages/login/login.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';

import { ServerService } from './shared/services/server.service';
import{ImagevalidationService} from './shared/services/imagevalidation.service';
import { OurteamComponent } from './components/pages/ourteam/ourteam.component';
import { AdminHeaderComponent } from './components/common/admin-header/admin-header.component';
import { CrudBlogComponent } from './components/admin/crud-blog/crud-blog.component';
import { CrudOurTeamComponent } from './components/admin/crud-our-team/crud-our-team.component';
import { CrudGalleryComponent } from './components/admin/crud-gallery/crud-gallery.component';
import { BlogListComponent } from './components/admin/blog-list/blog-list.component';
import { OurteamListComponent } from './components/admin/ourteam-list/ourteam-list.component';
import { GalleryListComponent } from './components/admin/gallery-list/gallery-list.component';
import { CrudNewsComponent } from './components/admin/crud-news/crud-news.component';
import { NewsListComponent } from './components/admin/news-list/news-list.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsdetailsComponent } from './components/pages/newsdetails/newsdetails.component';
import { ArchivejobsComponent } from './components/pages/archivejobs/archivejobs.component';
// import { RecaptchaModule } from 'ng-recaptcha';
// import { RecaptchaFormsModule } from 'ng-recaptcha';
import { AdminDashboardComponent } from './components/admin/admin-dashboard/admin-dashboard.component';
import { ArchivedetailsComponent } from './components/pages/archivedetails/archivedetails.component';
import { ResumeComponent } from './components/pages/resume/resume.component';
import { CrudArchivejobComponent } from './components/admin/crud-archivejob/crud-archivejob.component';
import { ArchievejobListComponent } from './components/admin/archievejob-list/archievejob-list.component';
import { RemoveHtmltagsPipe } from './shared/pipes/remove-htmltags.pipe';
import { ProductsComponent } from './components/pages/products/products.component';
import { ProductsModule} from './components/pages/products/products.module';
import { CareersComponent } from './components/pages/careers/careers.component';
import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RecaptchaFormsModule
} from 'ng-recaptcha';
import { CrudTitleComponent } from './components/admin/crud-title/crud-title.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    Home1Component,
    AboutComponent,
    ServicesComponent,
    PortfolioComponent,
    ConsultingComponent,
    BlogdetailsComponent,
    ContactComponent,
    LoginComponent,
    GalleryComponent,
    OurteamComponent,
    AdminHeaderComponent,
    CrudBlogComponent,
    CrudOurTeamComponent,
    CrudGalleryComponent,
    BlogListComponent,
    OurteamListComponent,
    GalleryListComponent,
    CrudNewsComponent,
    NewsListComponent,
    BlogComponent,
    NewsComponent,
    NewsdetailsComponent,
    ArchivejobsComponent,
    AdminDashboardComponent,
    ArchivedetailsComponent,
    ResumeComponent,
    CrudArchivejobComponent,
    ArchievejobListComponent,
    ImageCropperComponent,
    RemoveHtmltagsPipe,
    ProductsComponent,
    CareersComponent,
    CrudTitleComponent,
    PrivacyPolicyComponent,
   
    
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AnimateSliderModule ,
    AngularEditorModule,
    CKEditorModule,
    NgxSpinnerModule, ProductsModule,
   
  ],
  providers: [ServerService, ImagevalidationService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        // siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        siteKey: '6LeHhvcbAAAAAKB67y5oBZKKupPgfY8DeWUMjnlW'
      } as RecaptchaSettings
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
