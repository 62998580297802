
<div role="main">


<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/gallery.jpg" alt="First slide">
      </div>
    </div>
  </div>
 
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Gallery
      </li>
    </ol>
  </nav>
  <section class="section-padding-ash">
    <div class="container">
      <h1 aria-label="heading"></h1>
      <div class="row">
        <div class="col-md-12 col-sm-10 col-xs-12 mobilepad">
          <div class="portfolioContainer" *ngIf="nodata==false">
            <div class="row">
               <!-- Spinner start -->
                <ngx-spinner
                bdColor="rgba(51,51,51,0.8)"
                size="default"
                color="#fd6121"
                type="line-scale-pulse-out-rapid"
                [fullScreen] = "false" > </ngx-spinner>
          <!-- Spinner end -->
              <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 objects mobilepad"  *ngFor="let Gallery of GalleryList; let i = index">
                <div class="grid objects" (click)="openModal();currentSlide(i+1);" >
                  <figure class="effect-lily gallerycount"> <img [src]="Gallery.ImagePath" style="margin-right: 50px;" height="150px" width="180px" alt="image"/>
                    <!-- <figcaption><p><a class="info magnific-popup" href="assets/images/portfolio/gallery-1.png"><b>View Large</b></a></p> </figcaption> -->
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div  *ngIf="nodata==true" >
               <div class="Nodata"> No Images Found</div>
               </div>
        </div>
      </div>
     
    </div>
  </section>
  <div id="myModal" class="modal">
    <div class="fa fa-expand closefullScreen cursor " *ngIf="fullscreen"  (click)="openFullscreen()"></div>
    <div class="fa fa-compress closefullScreen cursor" *ngIf="offscreen" (click)="closeFullscreen()"></div>
    <span class="close cursor" (click)="closeModal()">&times;</span>
    <span class="Indexcolor">{{slideIndex}}/{{len}}</span>
    <div class="modal-content">

      <div class="mySlides" (click)="openFullscreen()" *ngFor="let Gallery of GalleryList; let i = index">
        <img [src]="Gallery.ImagePath" alt="No Image" [ngClass]="{sliderImages: fullscreen, sliderImagesFull: offscreen}">

      </div>
      <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
      <a class="next" (click)="plusSlides(1)">&#10095;</a>


    </div>
  </div>
</div>