
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

 const API_URL=environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(
    private http: HttpClient, private router: Router
    
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
   }

   public get currentUserValue(): any {
      
    return this.currentUserSubject.value;
}

Login(data) {
  return this.http.post<any>(`${environment.API_URL}/Login`,data)
      .pipe(map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
      }));
}

logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('adminID');
  this.currentUserSubject.next(null);
  this.router.navigateByUrl('/home').then(()=>{
    window.location.reload();
  })
}

// constructor(){

// }
gettoken(){  
return !!localStorage.getItem("user");  
}  
}

