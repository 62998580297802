<div role="main">
<div>
  <img class="img-fluid" src="assets/images/VRS.png" alt="Spino Image">
</div>
<div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="Homepage" routerLink="/home">Home
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Visitor Management System
      </li>
    </ol>
  </nav>
</div>
<div class="container-fluid p-4">
  <h1 aria-label="heading"></h1>
  <br>
  <div class="font-size"><b>Visitor Management System : </b></div>
  <br>
  <p>A smart visitor management system for efficient and secure visitor operations. A visitor self-service kiosk makes
    your reception operations hassle-free and independent.</p>

  <p>Visitor management system, Visitor streamlines the check-in process by capturing images, Entering personal details
    like Name, phone number, and contact person, and printing visitor pass. Digital check-in for the visitors,
    eliminating the hectic paperwork. Send invites to your visitors prior to their visit to your premises.</p>

  <p>Our visitor management system consists of a printer that self-generates the visitor slip - a paper slip that also
    detaches on its own.</p>

  <p>Pre-appointment e-pass provide your guests with a seamless entry. It saves the time and effort of both guests and
    the security/reception operators.</p>

  <p>The host creates the visitor e-pass for the guest on the Entry-point visitor app. The guest just needs to show the
    pass at the entry. Admins and designated authorities can create epasses for multiple guests at one go.</p>

  <p >A digital visitor management system does the core job of checking in visitors. But it also does
    many things a pen and paper can’t:</p>

    <ul class="font-color">
      <li>Maintain a visitor log.</li>
      <li>Take photos of your visitors to help you identify them.</li>
      <li>Print visitor passes.</li>
      <li>Allow two-way communication between employees and visitors.</li>
      <li>Provide instructions for visitors.</li>
      <li>and more...</li>
    </ul>
  
  <p>A visitor management system increases your office productivity, enhances your office security, and elevates your
    brand image.</p>

</div>
</div>