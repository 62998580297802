import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/shared/services/server.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-archievejob-list',
  templateUrl: './archievejob-list.component.html',
  styleUrls: ['./archievejob-list.component.css']
})
export class ArchievejobListComponent implements OnInit {

  constructor(private serverService: ServerService, private router:Router) { }

  model: any ={};
  List: any;
  Fail:boolean;
  Success:boolean;
  nodata= false;
  successMessage:any;
  errorMessage:any;
  private unsubscribe$ = new Subject<void>();
  archivejobList : any;
  ngOnInit(): void {
    this.FetchArchivejoblist();
  }
  FetchArchivejoblist(){
    const payload:any={}
    payload.AccountId=2
    
    this.serverService.FetchArchiveJobs(payload)
    .subscribe(
   (data:any )=>{ 
   
      this.archivejobList={};
     
            this.archivejobList =data.ArchiveJobsList;
        
            if( this.archivejobList== undefined){
              this.nodata=true;
            }
            else{
              this.nodata=false;
            }
            // console.log(this.BlogList);
   } );

  }
        //to edit and update blog
  upadte(user){
    this.serverService.setdata(user);
    
    this.router.navigateByUrl('/CrudArchive');
    
      }

   Delete(JobId, Status){
        this.model={};
         this.model.JobId=JobId;
         
        if(Status==='Yes'){
         // debugger;
         this.serverService.DeleteArchiveJob( this.model.JobId).subscribe(
           res=>{
             // console.log(res)
            
              if(res['Response'].status ==200){ 
               this.Success =true;
              this.successMessage= res['ArchiveJobsData'].ReturnVal;
              setTimeout(() => {
               this.Success = false;
               this.successMessage = '';
                       }, 5000);
                       
                       this.FetchArchivejoblist();
                       // this.FetchBlogList();
              }
              else{
                this.Fail=true;
                this.errorMessage= 'Error while processing';
                setTimeout(() => {
                 this.Fail = false;
                 this.errorMessage = '';
                         }, 5000);
              }
             });
           
               // this.FetchBlogList();
                    
            
           }
          
         //  this.FetchArchivejoblist();
           
           
       }  
       
}
