<header class="innner-page" *ngIf="newORupdate">
  <div class="container">
    <h1><i class="fa fa-angle-double-right" aria-hidden="true"></i>Add News</h1>
  </div>
</header>
<nav aria-label="breadcrumb" *ngIf="newORupdate">
  <ol class="breadcrumb no-gutters">
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="Homepage" routerLink="/dashboard" skipLocationChange>Home
      </a>
    </li>
    <li class="breadcrumb-item">
      <a placement="bottom" ngbTooltip="News" routerLink="/newslist" skipLocationChange>News List
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Add News
    </li>
  </ol>
</nav>
  <div class="container" *ngIf="newORupdate" role="main">
    <div class="alert alert-success" *ngIf="Success">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <p>{{ successMessage }}</p>
    </div>
    <div class="col-md-12 admpad">
    <form #f="ngForm" name="form" class="pure-form pure-form-stacked formborder" (ngSubmit)="f.form.valid && onSubmit(f)"  novalidate>
    <div class="col-md-12">
    <div class="form-group">
      <span class="h6">Title :</span>
      <input type="text" class="form-control" name="title" [(ngModel)]="model.title" #title="ngModel"
        placeholder="Enter Your Title" [ngClass]="{ 'is-invalid': f.submitted && title.invalid }" required />
      <div *ngIf="(title.touched ||f.submitted) && title.invalid" class="invalid-feedback">
        <div *ngIf="title.errors.required">Title is required</div>
      </div> 
    </div>
    </div>
    <div class="col-md-12">
    <div class="form-group">
      <span class="h6">Description :</span>
      <textarea class="form-control" name="description" [(ngModel)]="model.description"
        [ngClass]="{ 'is-invalid': f.submitted && description.invalid }" placeholder="Type here..." required
        #description="ngModel" rows="7" maxlength="4000"></textarea>
      <div *ngIf="f.submitted && description.invalid" class="invalid-feedback">
        <div *ngIf="description.errors.required">Description is empty</div>
        <div *ngIf="description.errors.maxlength">Description should not exceed 4000 letters</div>
      </div>
    </div>
    </div>
    <div class="col-md-8">
    <div class="form-group">
        <input *ngIf="!imgURL" aria-label="img"  type="file" name="image" (change)="fileChangeEvent($event)" accept="image/*"
        #fileInput>

      <div>
        <img *ngIf="imgURL" [src]="imgURL" alt="No Image" height="100" width='100'/>
      </div>
      <div *ngIf="f.submitted && !imgURL" style="color:#dc3545; font-size: 12.8px;">Please select image</div>
    </div>
   </div>
    <div class="col-md-8 padbtm">
        <button type="submit" class="col-md-3 btn btn-primary">Submit</button>
        <button type="reset" class="col-md-3 btn btn-cancel" (click)="CancelNews()">Cancel</button>
      </div>
  </form>
  </div>
  </div>


<!-- ********************************Update OR Edit Form ************************************* -->
<header class="innner-page"  *ngIf="!newORupdate">
  <div class="container">
    <h1><i class="fa fa-angle-double-right" aria-hidden="true"></i>Update News</h1>
  </div>
</header>
<div class="col breadcrumb-wizard"  *ngIf="!newORupdate">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb no-gutters">
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="Home" routerLink="/dashboard">Home
        </a>
      </li>
      <li class="breadcrumb-item">
        <a placement="bottom" ngbTooltip="News List" routerLink="/newslist">News List
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Update News
      </li>
    </ol>
  </nav>
</div>
  <div class="container"  *ngIf="!newORupdate" role="main">
    <div class="alert alert-success" *ngIf="Success">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <p>{{ successMessage }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="Fail">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <p>{{ errorMessage }}</p>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-12 admpad">
        <form #f="ngForm" aria-label="form" name="form" class="pure-form pure-form-stacked formborder" (ngSubmit)="f.form.valid && onSubmit(f)"
          novalidate>
          <div class="row">
            <div class="col-md-2 col-lg-6"></div>
            <div class="col-md-10 col-lg-6 col-sm-12 Buttonalign text-right">
              <button type="submit" class="konnect-submit">Submit</button>&nbsp;&nbsp;
              <button type="reset" class="konnect-submit" (click)="CancelNews()">Cancel</button>&nbsp;&nbsp;
              <button type="button" class="konnect-submit" data-toggle="modal" data-target="#myModal">Delete</button>
            </div>
            </div>
      <div class="col-md-12">
        <div class="form-group img-container">
          <!-- <input type="file" *ngIf="!imgURL" name="image" (change)="fileChangeEvent($event)" accept="image/*"
            #fileInput> -->
          
          <div  class="user-img-frame" >
            <div class="user-img">

            
            <img [src]="model.ImagePath"  alt="No Image" height="150" width='150' *ngIf="model.ImagePath">
            <!-- <a class="close" *ngIf="imgURL" (click)="cancelImg()"><i class="fa fa-close"
                style="font-size:16px;color:red"></i></a> -->
              </div>
                <a class="editImg" data-toggle="modal" data-target="#Img" *ngIf="model.ImagePath">
                  <i class="fa fa-edit"></i></a>
               
          </div>
        </div> 
        <div class="form-group">
          <span class="h6">Title :</span>
          <input type="text" class="form-control" name="title" [(ngModel)]="model.title" #title="ngModel"
            placeholder="Enter Your Title" [ngClass]="{ 'is-invalid': f.submitted && title.invalid }" required />
          <div *ngIf="(title.touched ||f.submitted) && title.invalid" class="invalid-feedback">
            <div *ngIf="title.errors.required">Title is required</div>
          </div>
        </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">   
            <span class="h6">Description :</span>
              <textarea class="form-control" name="description" [(ngModel)]="model.description"
                [ngClass]="{ 'is-invalid': f.submitted && description.invalid }" placeholder="Type here..." required
                #description="ngModel" rows="7" maxlength="4000"></textarea>
              <div *ngIf="f.submitted && description.invalid" class="invalid-feedback">
                <div *ngIf="description.errors.required">Description is empty</div>
                <div *ngIf="description.errors.maxlength">Description should not exceed 4000 letters</div>
              </div>
            </div>
        </div>
        </form>
      </div>
      </div>
    </div>
  </div>
  
<!-- data-toggle="modal" data-target="#myModal" -->
<div id="myModal" class="modal fade">
	<div class="modal-dialog modal-confirm">
		<div class="modal-content">
			<div class="modal-header">
				<div class="icon-box">
					<i class="fa fa-times"></i>
				</div>				
				<h4 class="modal-title">Are you sure?</h4>	
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
			</div>
			<div class="modal-body">
				<p>Do you really want to delete this record? This process cannot be undone.</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
				<button type="button" class="btn btn-danger"  data-dismiss="modal"  (click)="DeleteNews()" >Delete</button>
			</div>
		</div>
	</div>
</div>  
  
  



<div class="modal" id="Img" aria-hidden="true" aria-labelledby="ImgModalToggleLabel" tabindex="-1">
  <button id="dismissImgModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-dialog ">

      <div class="modal-content">
          <div class="modal-head text-center py-2">
              <h3>Upload Image</h3>
          </div>
          <div class="modal-body text-center">
              <div *ngIf="!imgURL" class="edit-user-img">
                  <img *ngIf="!imgURL && model.ImagePath" [src]="model.ImagePath"
                      class="mx-auto d-block rounded-circle border" alt="profile" width="150" height="150">

              </div>
              <input style="display: none" type="file" name="image" (change)="fileChangeEvent($event)"
                  accept="image/*" #fileInput multiple >
              <a *ngIf="!imgURL" (click)="fileInput.value = ''" (click)="fileInput.click()"><i class="fa fa-upload"
                      aria-hidden="true">
                      Upload</i></a>
              <img *ngIf="!imgURL && !model.ImagePath" class="mx-auto d-block rounded-circle border" alt="avatar1"
                  width="100" height="100">

              <div *ngIf="imgURL">
                  <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                      [aspectRatio]="4 / 3" [resizeToWidth]="128" [cropperMinWidth]="128" [onlyScaleDown]="true"
                      [roundCropper]="false" format="png" [canvasRotation]="canvasRotation" [transform]="transform"
                      (mouseWheelUp)="zoomIn($event)" (mouseWheelDown)="zoomOut($event)" outputType="base64"
                      (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                      (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" style="max-height: 33vh"
                      [style.display]="showCropper ? null : 'none'">
                  </image-cropper>
              </div>
              <label class="custom-file">


                  <!-- <button *ngIf="!imgURL" type="button" class="btn btn-primary btn-sm mr-1" 
                  placement="bottom" ngbTooltip="Update Profile Picture">
                  
                </button> -->

                  <button *ngIf="imgURL" type="button" class="btn btn-primary btn-sm mr-1" (click)="rotateLeft()"
                      ngbTooltip="left">
                      <i class="fa fa-rotate-left" aria-hidden="true"> </i>
                  </button>
                  <button *ngIf="imgURL" type="button" class="btn btn-primary btn-sm mr-1" (click)="rotateRight()"
                      ngbTooltip="right">
                      <i class="fa fa-rotate-right" aria-hidden="true"> </i>
                  </button>
                  <button *ngIf="imgURL" type="button" class="btn btn-primary btn-sm mr-1" (click)="flipHorizontal()"
                      ngbTooltip="horizontal">
                      <i class="fa fa-arrows-h" aria-hidden="true"> </i>
                  </button>
                  <button *ngIf="imgURL" type="button" class="btn btn-primary btn-sm mr-1" (click)="flipVertical()"
                      ngbTooltip="vertical">
                      <i class="fa fa-arrows-v" aria-hidden="true"> </i>
                  </button>
                  <button *ngIf="imgURL" type="button" class="btn btn-success btn-sm mr-1" (click)="onUpload()"
                      ngbTooltip="For save image click upload">
                      <i class="fa fa-upload" aria-hidden="true"> </i>
                  </button>

                  <button *ngIf="imgURL" type="button" class="btn btn-danger btn-sm mr-1" (click)="cancelImage()"
                      (click)="fileInput.value = ''" ngbTooltip="Cancel">
                      <i class="fa fa-times-circle" aria-hidden="true"> </i>
                  </button>

              </label>

              <div>
                  <img *ngIf="imgURL" [src]="croppedImage" width="150" height="150" />
                  <!--<button *ngIf="imgURL" type="button" class="btn btn-outline-success btn-sm" (click)="onUpload()"
                ngbTooltip="For save image click upload">
                <span class="icon-upload"></span>
                  </button>
                 <button *ngIf="imgURL" type="button" class="btn btn-outline-danger btn-sm" (click)="cancelImage()"
                ngbTooltip="Cancel">
                <span class="icon-times-rectangle-o"></span>
                  </button>-->
              </div>



          </div>
      </div>
  </div>


</div>