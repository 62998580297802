import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from 'src/app/shared/services/server.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-crud-title',
  templateUrl: './crud-title.component.html',
  styleUrls: ['./crud-title.component.css']
})
export class CrudTitleComponent implements OnInit {
  model: any = {};
  newORupdate: boolean;
  //JobList: any=[{'Title':"Web developer", 'titleId':1},{'Title':"IOS developer", 'titleId':2},{'Title':"Android developer", 'titleId':3}]
  JobList: any;
  Jobmodel: any = {};
  title: string;
  Success: any;
  Fail: any;
  errorMessage: any;
  successMessage: any;

  nodata = false;
  @ViewChild('f') formValues;
  private unsubscribe$ = new Subject<void>();
  titleobj: any;
  constructor(
    private serverService: ServerService, private router: Router,) { }

  ngOnInit() {
   // this.NewOrUpdate();
    this.model = {};
    //this.updateJobTitle(this.model);
    this.FetchJobTitleList();
  }

  // onSubmitStatic(f: NgForm) {
  //   const data: any = {};
  //   data.Title = this.model.title;
  //   data.titleId = this.JobList.length + 1;
  //   this.JobList.push(data);
  //   this.formValues.resetForm();
  // }

  onSubmit(f: NgForm) {
    const data = this.model;
    debugger

    if (!data.hasOwnProperty('title_id')) {

      this.Jobmodel.title = this.model.title;

      this.serverService.AddJobTitle(this.Jobmodel).subscribe(
        res => {
          if (res['Response'].status == 200) {

            this.Success = true;
            this.successMessage = res['JobtileData'].ReturnVal;
            setTimeout(() => {
              this.Success = false;
              this.successMessage = '';
            }, 3000);
            this.FetchJobTitleList();
          }
          else {
            this.Fail = true;
            this.errorMessage = res['JobtileData'].ReturnVal;
            setTimeout(() => {
              this.Fail = false;
              this.errorMessage = '';
            }, 3000);
          }


        });
      this.formValues.resetForm();
    }
    else {
      const obj: any = {};
      obj.title_id = this.model.title_id;
      obj.title = this.model.title;


      this.serverService.ModifyJobTitle(obj).subscribe(
        res => {
          if (res['Response'].status == 200) {

            this.Success = true;
            this.successMessage = res['JobtileData'].ReturnVal;
            setTimeout(() => {
              this.Success = false;
              this.successMessage = '';
            }, 3000);
            this.FetchJobTitleList();
          }
          else {
            this.Fail = true;
            this.errorMessage = res['JobtileData'].ReturnVal;
            setTimeout(() => {
              this.Fail = false;
              this.errorMessage = '';
            }, 3000);
          }

        });
      this.formValues.resetForm();


      // setTimeout(()=>{
      //   this.router.navigateByUrl('/bllist')
      // },3000)

    }

  }
  updateJobTitle(u) {
    this.model = u;
    if (this.model == undefined) {
      this.model = {}
    }
    else {
      this.model.title = u.title;
      this.model.title_id = u.title_id;


    }
    // this.serverService.blogdatatoedit = undefined;

  }

  FetchJobTitleList() {
    //this.model.AccountId = 2

    this.serverService.FetchJobTitleList().pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: any) => {
          // console.log(data);
          if (data.Response.status = '200') {
            this.JobList = data.JobtileList;
          } else if (data.Response.status = '404') {
            this.nodata = true;
          } else {
            this.Fail = true;
            this.errorMessage = "error Occured!!"
            setTimeout(() => {
              this.Fail = false;
              this.errorMessage = '';
            }, 3000);


          }


        });

  }

  // NewOrUpdate() {

  //   this.model = this.serverService.getdata();
  //   if (this.model == undefined) {
  //     this.newORupdate = true;
  //   }
  //   else {
  //     this.newORupdate = false;
  //   }

  // }
  CancelJobTitle() {

    this.formValues.resetForm();


  }
  DeleteJobTitle(payload, status) {
    if('title_id' in payload ){
      this.titleobj=payload;
    }
    
    if (status == 'Yes') {
      const obj: any = {}
      obj.title_id = this.titleobj.title_id;

      this.serverService.DeleteJobTitle(obj).subscribe(
        res => {
          if (res['Response'].status == 200) {
            if (res['JobtileData'].ReturnVal == "Record deleted successfully!") {
              this.Success = true;
              this.successMessage = res['JobtileData'].ReturnVal;
              setTimeout(() => {
                this.Success = false;
                this.successMessage = '';
              }, 3000);
              this.FetchJobTitleList();
            } else {
              this.Fail = true;
              this.errorMessage = 'Error while processing';
              setTimeout(() => {
                this.Fail = false;
                this.errorMessage = '';
              }, 3000);
            }

          }
          else {
            this.Fail = true;
            this.errorMessage = 'Error while processing';
            setTimeout(() => {
              this.Fail = false;
              this.errorMessage = '';
            }, 3000);
          }
        }


      );

    } else {

    }

  }


}
