<!-- <div class="display-fex"> -->

  <header class="innner-page">
    <div class="container">
      <h1><i class="fa fa-angle-double-right" aria-hidden="true"></i> Blog List</h1>
    </div>
  </header>
  <div class="col breadcrumb-wizard">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb no-gutters">
        <li class="breadcrumb-item">
          <a placement="bottom" ngbTooltip="Home" routerLink="/dashboard">Home
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Blog List
        </li>
      </ol>
    </nav>
  </div>
  <div class="container" role="main">
    <div class="row ">
      <div class="col-md-4">
      </div>
      <div class="col-md-4 text-center">
        <h2 > Blog List</h2>
      </div>
      <div class="col-md-4 text-right addpad">
        <button routerLink="/crudblog" class="btn btn-success button-blue btn-border-radius"  aria-expanded="false" aria-label="addblog" >
          <span>Add Blog</span>
        </button>
      </div>

    </div>
    <div class="row" >
      <div class="col-sm-12">
        <div class="alert alert-success" role="alert" *ngIf="Success">
          <button type="button" class="close" data-dismiss="alert">&times;</button>
          {{ successMessage }}
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="Fail">
          <button type="button" class="close" data-dismiss="alert">&times;</button>
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="nodata==false">
        <!-- <div class="row">
        <div class="col-lg-8 mx-auto table-responsive "> -->
          <div>
        <table class="table table-hover">
          <thead>
            <tr> 
              <th class="text-center">S.No</th>
              <th class="text-left">Image</th>
              <th class="text-left thpad">Title</th>
              <th class="text-left thpad">Description</th>
              <th >Delete</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let user of BlogList; let i = index">
              <td class="text-center">{{i+1}}</td>
              <td class="text-left" (click)="upadteBlog(user.ArticleId)">
                <img [src]="user.ImagePath" alt="user Image" height="30" width="70" style="margin-right:10px;">
              </td>
              <td class="text-left">

                <button type="button" class="btnn btn btn-link TitleText" (click)="upadteBlog(user)" placement="bottom"
                 aria-expanded="false" aria-label="blog"  ngbTooltip="Modify Blog">{{user.ArticleTitle| slice:0:30}}..
                </button>
              </td>
              <td class="text-left Descriptiontext">{{user.ArticleDescription| slice:0:50}}</td>

              <td>
                <div class="align-div-center">
                  <button type="button" class="btn btn-outline-danger" data-toggle="modal" data-target="#myModal"  aria-expanded="false" aria-label="delblog"
                    (click)="DeleteBlog(user.ArticleId, '')" placement="bottom" ngbTooltip="Delete Blog">
                    <span class="fa fa-times-circle-o"></span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
          </div>
      </div>
      <div class="col-md-12 " *ngIf="nodata==true">
        <div class="Nodata"> No Blogs Found</div>
      </div>
    </div>
  </div>
  <!-- data-toggle="modal" data-target="#myModal" -->
  <div id="myModal" class="modal fade" role="alertdialog" name="alert">
    <div class="modal-dialog modal-confirm">
      <div class="modal-content">
        <div class="modal-header">
          <div class="icon-box">
            <i class="fa fa-times"></i>
          </div>
          <h3  class="modal-title">Are you sure?</h3>
          <button type="button" class="close" data-dismiss="modal" >&times;</button>
        </div>
        <div class="modal-body">
          <p>Do you really want to delete this record? This process cannot be undone.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal"
            (click)="DeleteBlog(model, 'Yes')">Delete</button>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->
