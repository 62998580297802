import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { Home1Component } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { PortfolioComponent } from './components/pages/clients/clients.component';
import { ConsultingComponent } from './components/pages/consulting/consulting.component';
import { ArchivejobsComponent } from './components/pages/archivejobs/archivejobs.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogdetailsComponent } from './components/pages/blogdetails/blogdetails.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsdetailsComponent } from './components/pages/newsdetails/newsdetails.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { LoginComponent } from './components/pages/login/login.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { OurteamComponent } from './components/pages/ourteam/ourteam.component';
import { ArchivedetailsComponent } from './components/pages/archivedetails/archivedetails.component';
import { ResumeComponent } from './components/pages/resume/resume.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { CareersComponent } from './components/pages/careers/careers.component';

// ADMIN ROUTES
import { CrudBlogComponent } from './components/admin/crud-blog/crud-blog.component';
import { BlogListComponent } from './components/admin/blog-list/blog-list.component';
import { CrudOurTeamComponent } from './components/admin/crud-our-team/crud-our-team.component';
import { OurteamListComponent } from './components/admin/ourteam-list/ourteam-list.component';
import { CrudGalleryComponent } from './components/admin/crud-gallery/crud-gallery.component';
import { GalleryListComponent } from './components/admin/gallery-list/gallery-list.component';
import { CrudNewsComponent } from './components/admin/crud-news/crud-news.component';
import { NewsListComponent } from './components/admin/news-list/news-list.component';
import { AdminDashboardComponent } from './components/admin/admin-dashboard/admin-dashboard.component';
import { CrudArchivejobComponent } from './components/admin/crud-archivejob/crud-archivejob.component';
import { ArchievejobListComponent } from './components/admin/archievejob-list/archievejob-list.component';
import { AuthGuard } from './Auth/auth.guard';
import { CrudTitleComponent } from './components/admin/crud-title/crud-title.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';


const routes: Routes = [
	{path: '', component: Home1Component},
    {path: 'home', component: Home1Component},
	{path: 'about', component: AboutComponent},
	{path: 'services', component: ServicesComponent},
	{path: 'clients', component: PortfolioComponent},
	{path: 'consulting', component: ConsultingComponent},
	{path: 'archivejobs', component: ArchivejobsComponent},
	{path: 'blog', component: BlogComponent},
	{path: 'blogdetails', component: BlogdetailsComponent},
	{path: 'news', component: NewsComponent},
	{path: 'newsdetails', component: NewsdetailsComponent},
	{path: 'contact', component: ContactComponent},
	{path: 'careers', component: CareersComponent},
	{path: 'login', component: LoginComponent},
	{path: 'gallery', component: GalleryComponent},
	{path: 'ourteam', component: OurteamComponent},
	{path:'privacypolicy', component:PrivacyPolicyComponent},
	{path: 'archivedetails', component: ArchivedetailsComponent},
	{path: 'Resumeupload', component: ResumeComponent},
	{path: 'products',loadChildren: () => import('./components/pages/products/products.module').then(m => m.ProductsModule)},


	// ADMIN ROUTES
	{path: 'crudblog', component: CrudBlogComponent , canActivate:[AuthGuard]},
	{path: 'bloglist', component: BlogListComponent, canActivate:[AuthGuard]},
	{path: 'crudteam', component: CrudOurTeamComponent, canActivate:[AuthGuard]},
	{path: 'ourteamlist', component: OurteamListComponent, canActivate:[AuthGuard]},
	{path: 'crudgallery', component: CrudGalleryComponent, canActivate:[AuthGuard]},
	{path: 'gallerylist', component: GalleryListComponent, canActivate:[AuthGuard]},
	{path: 'crudnews', component: CrudNewsComponent, canActivate:[AuthGuard]},
	{path: 'newslist', component: NewsListComponent, canActivate:[AuthGuard]},
	{path: 'dashboard', component: AdminDashboardComponent, canActivate:[AuthGuard]},
	{path: 'CrudArchive', component: CrudArchivejobComponent, canActivate:[AuthGuard]},
	{path: 'archivejoblist', component: ArchievejobListComponent, canActivate:[AuthGuard]},
	{path: 'addtitle', component: CrudTitleComponent, canActivate:[AuthGuard]}
	];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
