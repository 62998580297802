<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="footer-item">
                    <img class="img-fluid" src="assets/images/spino_logo.png" alt="logo">
                    <p> We at Spino offer a wide range of IT and non-IT services, with world-class quality and support.
                        <br />Our main services Involved:<br />
                    </p>
                    <ul class="l">
                        <li>IT Development</li>
                        <li>Consulting</li>
                        <li>Enterprise Application</li>
                    </ul>

                </div>

                <div class="footer-item">
                    <ul class="social">
                        <li><a (click)="redirect()" routerLink="/home" aria-label="fb"><i
                                    class="icofont icofont-social-facebook"></i></a></li>
                        <li><a (click)="redirect()" routerLink="/home" aria-label="tw"><i
                                    class="icofont icofont-social-twitter"></i></a></li>
                        <li><a (click)="redirect()" routerLink="/home" aria-label="ln"><i
                                    class="icofont icofont-social-linkedin"></i></a></li>
                        <li><a (click)="redirect()" routerLink="/home" aria-label="pn"><i
                                    class="icofont icofont-social-pinterest"></i></a></li>
                        <li><a (click)="redirect()" routerLink="/home" aria-label="dr"><i
                                    class="icofont icofont-social-dribble"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 ">
                <div class="footer-item">
                    <span class="h4heading">Quick Links</span>
                    <ul>
                        <li><a (click)="redirect()" routerLink="/home">Home</a></li>
                        <li><a (click)="redirect()" routerLink="/about">About Us</a></li>
                        <li><a (click)="redirect()" routerLink="/services">Services</a></li>
                        <li><a (click)="redirect()" routerLink="/consulting">Consulting</a></li>
                        <li><a (click)="redirect()" routerLink="/clients">Clients</a></li>
                        <li><a (click)="redirect()" routerLink="/archivejobs">Archive Jobs</a></li>
                        <li class="dropdown-box">
                            <a class="cursor" data-toggle="collapse" data-target="#collapseid" aria-expanded="true"
                                aria-controls="collapseid">Products</a>
                            <ul class="collapse " id="collapseid">
                                <li>
                                    <a class="font-size-small" (click)="redirect()" routerLink="/token"> Token
                                        Management System </a>
                                </li>
                                <li>
                                    <a class="font-size-small" (click)="redirect()" routerLink="/visitor"> Visitor
                                        Management System </a>
                                </li>
                                <li>
                                    <a class="font-size-small" (click)="redirect()" routerLink="/itsmyturn"> ItsMyturn
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="footer-item">
                    <span class="h4heading">Categories</span>
                    <ul>
                        <li><a (click)="redirect()" routerLink="/blog">Blog</a></li>
                        <li><a (click)="redirect()" routerLink="/news">News</a></li>
                        <li><a (click)="redirect()" routerLink="/ourteam">Our Team</a></li>
                        <li><a (click)="redirect()" routerLink="/gallery">Gallery</a></li>
                        <li><a (click)="redirect()" routerLink="/contact">Contact Us</a></li>
                        <li><a (click)="redirect()" routerLink="/careers">Careers</a></li>
                        <li><a (click)="redirect()" routerLink="/privacypolicy">PrivacyPolicy</a></li>
                        
                    </ul>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="footer-item">
                    <span class="h4heading">Recent Blogs</span>
                    <ul class="cursor">
                        <li *ngFor="let blog of BlogList| slice:0:5"><a (click)="Viewblog(blog)">{{blog.ArticleTitle|
                                slice:0:30}}</a></li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="footer-bottom">
                    <p>Copyright ©{{currentYear}} SPINO INC. All Rights Reserved</p>
                </div>
            </div>
        </div>
    </div>
</footer>
<div id="qnimate" class="off">
    <div id="search" class="open">
        <button data-widget="remove" id="removeClass" class="close" type="button">×</button>
        <form action="#" method="post" autocomplete="off">
            <input type="text" placeholder="Type search keywords here" value="" name="term" id="term">
            <button class="btn btn-primary btn-xl" type="submit"><span class="glyphicon glyphicon-search"></span>
                Search</button>
        </form>
    </div>
</div>