import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/shared/services/server.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  model: any ={};
  NewsList: any;
  nodata: any = false;
  constructor(private serverService: ServerService, private router:Router, private location: Location, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);
    this.FetchNewsList();
  }
  FetchNewsList(){
    this.model.AccountId=2
    
    this.serverService.FetchArticles(this.model)    
    .subscribe(
   (data:any )=>{ 
      this.NewsList={};
      // this.BlogList=data.ArticleList;
      const filterValue = "NEWS";
      const result = data.ArticleList.filter(element => {
        return element.ArticleType === filterValue;
      });
            this.NewsList =result;
            if( this.NewsList=="" || this.NewsList.length==0){
                  this.nodata=true;
                }
                else{
                  this.nodata=false;
                }
            
   } );

  }

  ViewNews(news){
    // this.serverService.setdata(news);
    // this.router.navigateByUrl("/newsdetails")
     

  const EncodeURL = btoa(news.ArticleId);
    // console.log(EncodeURL);
   this.router.navigate(["/newsdetails"], { queryParams: { q: EncodeURL } });
  }

}
